/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useLayoutEffect } from 'react'
import './App.css'
import './assets/langs/i18n'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { SnackbarProvider } from 'notistack'
import Pages from 'pages'

function App() {
  const { i18n } = useTranslation()
  const changeMetaData = () => {
    const EnDesc =
      'Founder of the MFAS Group Ltd., which includes many companies in diverse and interrelated fields that serve each other to be an integrated and unique group in terms of diversity, ideas and distinguished and professional cadres.'

    const ArDesc =
      'مؤسس مجموعة MFAS المحدودة التي تضم العديد من الشركات في مجالات متنوعة ومترابطة تخدم بعضها البعض بحيث تكون مجموعة متكاملة وفريدة من حيث التنوع والأفكار والكوادر المميزة والمحترفة.'

    const metaDescriptionTag = document.querySelector(
      'meta[name="description"]'
    )
    if (metaDescriptionTag) {
      metaDescriptionTag.setAttribute(
        'content',
        i18n.language === 'ar' ? ArDesc : EnDesc
      )
    } else {
      const newMetaTag = document.createElement('meta')
      newMetaTag.name = 'description'
      newMetaTag.content = EnDesc
      document.head.appendChild(newMetaTag)
    }

    // Clean up function to reset when component unmounts
    return () => {
      if (metaDescriptionTag) {
        metaDescriptionTag.setAttribute('content', EnDesc)
      }
    }
  }
  useEffect(() => {
    changeMetaData()
  }, [])
  useEffect(() => {
    changeMetaData()

    if (i18n.language !== 'ar') {
      document.documentElement.style.setProperty('--main-font', 'Montserrat')
    } else {
      document.documentElement.style.setProperty('--main-font', 'Aljazeera')
    }
  }, [i18n.language])
  const queryClient = new QueryClient()

  return (
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider maxSnack={3}>
        <Pages />
      </SnackbarProvider>
    </QueryClientProvider>
  )
}

export default App
