import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import CircleIcon from '@mui/icons-material/Circle'

type Props = {
  setControl: any
}

const MainControl = ({ setControl }: Props) => {
  const { t, i18n } = useTranslation()
  const TITLE_STYLES = {
    color: 'var(--main-color)',
    fontFamily: 'var(--main-font)',
    fontWeight: 900,
    fontSize: '34px',
    marginBottom: '40px',
    textAlign: 'center',
    '@media screen and (max-width:550px)': {
      fontSize: '24px',
      marginBottom: '20px',
    },
  }
  const SPAN_STYLES = {
    color: 'var(--main-color)',
    cursor: 'pointer',
    margin: i18n.language === 'en' ? '5px 5px 0 !important' : '0px 5px',
    fontFamily: 'var(--main-font)',
    fontSize: '22px',
    transition: '.3s',
    fontWeight: i18n.language === 'en' ? 600 : 'normal',
    '&.en': {
      fontFamily: 'var(--En-font)',
      fontWeight: 600,
      marginTop: '4px',
    },
    '&:hover': {
      color: 'rgb(51 136 119)',
    },
    '@media screen and (max-width:550px)': {
      fontSize: '18px',
      margin: '10px 25px !important',
      '&.en': {
        margin: '4px 5px 0 !important',
      },
    },
  }
  const CONTROL_STYLES = {
    fontFamily: 'var(--main-font)',
    fontSize: '24px',
    color: '#333',
    cursor: 'pointer',
    '@media screen and (max-width:550px)': {
      fontSize: '20px',
    },
  }
  return (
    <Box dir={i18n.dir()}>
      <Typography sx={TITLE_STYLES}>{t('admin.website-control')}</Typography>
      <Box
        display="flex"
        marginBottom="15px"
        onClick={() => setControl([3, 1])}
      >
        <CircleIcon
          sx={{
            color: 'var(--main-color)',
            fontSize: '16px',
            position: 'relative',
            top: '10px',
          }}
        />
        <Box margin="0px 10px" display="flex">
          <Typography sx={CONTROL_STYLES}>
            {t('admin.general-control')}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          '@media screen and (max-width:800px)': { display: 'block' },
        }}
        marginBottom="15px"
      >
        <Box display="flex">
          <CircleIcon
            sx={{
              color: 'var(--main-color)',
              fontSize: '16px',
              position: 'relative',
              top: '10px',
            }}
          />
          <Typography
            margin="0px 10px"
            sx={CONTROL_STYLES}
            onClick={() => setControl([0, 1])}
          >
            {t('admin.product-control')}
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            '@media screen and (max-width:550px)': {
              display: 'block',
            },
          }}
        >
          <Box sx={SPAN_STYLES} onClick={() => setControl([0, 1])}>
            {t('admin.add-product')}
          </Box>
          <Box sx={SPAN_STYLES} onClick={() => setControl([0, 2])}>
            {t('admin.edit-product')}
          </Box>
          <Box sx={SPAN_STYLES} onClick={() => setControl([0, 3])}>
            {t('admin.delete-product')}
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        marginBottom="15px"
        onClick={() => setControl([1, 1])}
      >
        <CircleIcon
          sx={{
            color: 'var(--main-color)',
            fontSize: '16px',
            position: 'relative',
            top: '10px',
          }}
        />
        <Box margin="0px 10px" display="flex">
          <Typography sx={CONTROL_STYLES}>{t('admin.page-control')}</Typography>
          <Box sx={SPAN_STYLES} className="en">
            About
          </Box>
        </Box>
      </Box>

      <Box
        display="flex"
        marginBottom="60px"
        onClick={() => setControl([2, 1])}
      >
        <CircleIcon
          sx={{
            color: 'var(--main-color)',
            fontSize: '16px',
            position: 'relative',
            top: '10px',
          }}
        />
        <Box margin="0px 10px" display="flex">
          <Typography sx={CONTROL_STYLES}>{t('admin.page-control')}</Typography>
          <Box sx={SPAN_STYLES} className="en">
            Contacts
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default MainControl
