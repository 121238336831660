import React, { FC } from 'react'
import { IApp } from 'interfaces/products'
import Card from './card'
import { Grid } from '@mui/material'

interface IAppsListProps {
  apps: IApp[]
}

const AppsList: FC<IAppsListProps> = ({ apps }) => {
  return (
    <Grid container spacing={0} className="list">
      {apps?.map((app, index: number) => (
        <Card key={app._id} app={app} index={index} />
      ))}
    </Grid>
  )
}

export default AppsList
