import Btn from '../../loading-button/Btn'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Admin } from 'api/api-handlers/Instance'
import API_ROUTES from 'api/routes'
import { useQueryClient } from '@tanstack/react-query'
import GetApps from '../get-apps/getApps'
import { IApp } from 'interfaces/products'
import { useSnackbar } from 'notistack'

const DeleteApp = () => {
  const { t, i18n } = useTranslation()
  const [item, setItem] = useState<IApp>()
  const [loading, setLoading] = useState(false)
  const query = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const HandleDelete = () => {
    if (item) {
      setLoading(true)
      Admin.delete(
        `${API_ROUTES.PRODUCT_ROUTES.CREATE_POST_DELETE}/${item?._id}`
      )
        .then(async (res) => {
          await query.resetQueries(['get-infinite-apps'])
          enqueueSnackbar(t('deleted'))
          setLoading(false)
        })
        .catch((err: any) => {
          if (err.response.status === 401 || err.response.status === 403) {
            localStorage.clear()
            window.location.reload()
          }
          setLoading(false)
          enqueueSnackbar(t('wrong'))
        })
    }
  }

  return (
    <Box dir={i18n.dir()} width="100%">
      <Box
        sx={{
          fontFamily: 'var(--main-font)',
          fontSize: i18n.language === 'ar' ? '20px' : '18px',
          width: '100%',
          marginBottom: '20px',
        }}
      >
        {t('admin.choose-delete-product')}
      </Box>
      <Box
        sx={{
          display: 'flex',
          '@media screen and (max-width:900px)': {
            justifyContent: 'space-between',
          },
          '@media screen and (max-width:670px)': {
            display: 'block',
          },
        }}
      >
        <GetApps item={item} setItem={setItem} />

        <Box
          sx={{
            margin: '0px 15px',
            '@media screen and (max-width:900px)': {
              margin: '0',
            },
            '@media screen and (max-width:450px)': {
              margin: '10px 0',
            },
            // position: 'absolute',
            // bottom: '40px',
            // display: 'flex',
            // width: '100%',
            // padding: '0px 40px',
            // justifyContent: i18n.language === 'en' ? 'right' : 'left',
          }}
        >
          <Btn
            type="out-main"
            text={t('admin.delete')}
            loading={loading}
            HandleClick={HandleDelete}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default DeleteApp
