import { useInfiniteQuery, useQuery } from '@tanstack/react-query'
import contactApi from 'api/api-handlers/contactApi'
import { IGetMessagesPayload } from 'interfaces/contact'

const useReasonsQuery = () => {
  const reasonResult = useQuery(
    ['get-reasons'],
    () => contactApi.getReasons(),
    {
      select: (data) => data.data,
    }
  )
  return reasonResult
}

const useMessageQuery = (options: IGetMessagesPayload & { offset: number }) => {
  const queryResult = useInfiniteQuery(
    ['get-infinite-messages'],
    async ({ pageParam = 0 }) => {
      const { data } = await contactApi.getMessages({
        ...options,
        offset: pageParam,
      })
      return {
        data: data,
        pageParam: pageParam + 1,
      }
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.length === options?.limit) {
          return lastPage.pageParam
        }
        return undefined
      },
    }
  )
  return queryResult
}
const contactQueries = { useReasonsQuery, useMessageQuery }

export default contactQueries
