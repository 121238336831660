import Header from '../components/Header/header'
import { Box } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Footer from '../components/About-footer/footer'
import InfiniteAppsList from 'components/Product-cards/InfiniteAppsList'
import productQueries from 'api/react-query/useAppQueries'

const HomePage = () => {
  const { i18n } = useTranslation()
  const BACK_IMAGE = {
    transition: 'all 1.5s',
    backgroundPosition: `${i18n.language !== 'ar' ? 'top left' : 'top right'}`,
  }

  const [offset] = useState(0)

  const [limit] = useState(100)

  const { data, fetchNextPage, hasNextPage } = productQueries.useAppQuery({
    offset,
    limit,
  })

  return (
    <Box>
      <header>
        <Box className="back-img" sx={BACK_IMAGE}></Box>
        <Header />
      </header>
      <InfiniteAppsList
        data={data}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        limit={limit}
      />
      <Footer />
    </Box>
  )
}

export default HomePage
