import { useInfiniteQuery } from '@tanstack/react-query'
import productsApi from 'api/api-handlers/productApi'
import { IGetAppPayload } from 'interfaces/products'

const useAppQuery = (options: IGetAppPayload & { offset: number }) => {
  const queryResult = useInfiniteQuery(
    ['get-infinite-apps'],
    async ({ pageParam = 0 }) => {
      const { data } = await productsApi.getApps({
        ...options,
        offset: pageParam,
      })
      return {
        data: data,
        pageParam: pageParam + 1,
      }
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage.data.length === options?.limit) {
          return lastPage.pageParam
        }
        return undefined
      },
    }
  )
  return queryResult
}
const productQueries = { useAppQuery }

export default productQueries
