import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CircleIcon from '@mui/icons-material/Circle'
import { useQuery } from '@tanstack/react-query'
import { Api } from 'api/api-handlers/Instance'
import API_ROUTES from 'api/routes'

function Footer() {
  const year = new Date().getFullYear()
  const { i18n } = useTranslation()
  const { data } = useQuery(['general'], async () => {
    let data = await Api.get(API_ROUTES.GENERAL_ROUTES.GET)
    return data.data.data
  })

  const ITEM_LIST = {
    fontFamily: 'var(--main-font)',
    // fontSize: '20px',
    margin: '0px 20px',
    marginBottom: '6px',
    // fontWeight: '400',
  }
  const [List, setList] = useState('\n')
  const [ListAr, setListAr] = useState('\n')

  useEffect(() => {
    if (data?.aboutEn.value && data?.aboutAr.value) {
      setList(data?.aboutEn.value)
      setListAr(data?.aboutAr.value)
    }
  }, [data])

  // console.log(JSON.stringify(List).split('\n'))

  return (
    <Box id="about">
      <Box
        dir={i18n.dir()}
        sx={{
          background:
            'linear-gradient(45deg,var(--first-footer-color) , var(--second-footer-color))',
          color: '#fff',
          padding: '40px 40px 35px',
          '@media screen and (max-width:500px)': {
            padding: '40px 20px 35px',
          },
        }}
      >
        {i18n.language !== 'ar' &&
          List.split('\n')?.map((el, index) => {
            return (
              <Box display="flex" key={index}>
                <CircleIcon
                  style={{
                    fontSize: '15px',
                    position: 'relative',
                    top: '6px',
                    margin: '0px 10px',
                  }}
                />
                <Typography sx={ITEM_LIST} fontSize="20px" fontWeight={500}>
                  {el}
                </Typography>
              </Box>
            )
          })}

        {i18n.language === 'ar' &&
          ListAr.split('\n')?.map((el, index) => {
            return (
              <Box display="flex" key={index}>
                <CircleIcon
                  style={{
                    fontSize: '15px',
                    position: 'relative',
                    top: '6px',
                    margin: '0px 10px',
                  }}
                />
                <Typography sx={ITEM_LIST} fontSize="21px" fontWeight={400}>
                  {el}
                </Typography>
              </Box>
            )
          })}
      </Box>
      <Box
        display="flex"
        justifyContent="center"
        padding="10px"
        width="100%"
        style={{ background: '#0B0B0B', color: '#fff' }}
      >
        <Typography fontFamily="var(--En-font)" fontWeight={800}>
          MFAS GROUP LIMITED
        </Typography>
        <Typography
          fontFamily="var(--En-font)"
          fontWeight={800}
          margin="0px 10px"
        >
          {year}
        </Typography>
      </Box>
    </Box>
  )
}

export default Footer
