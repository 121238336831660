import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IApp } from '../../interfaces/products'
import SeeMore from './seeMore'

const CARD_STYLES = {
  textAlign: 'center',
  border: '1px solid var(--main-color)',
  borderTop: 'none',
  position: 'relative',
  '.circle': {
    background: 'var(--main-color)',
    width: '20px',
    height: '20px',
    borderRadius: '50%',
    position: 'absolute',
    top: '-10px',
    right: '-10px',
  },
  // borderLeft: 'none',
  padding: '60px 10px 10px',
  '.icon': {
    width: '100px',
    display: 'block',
    margin: '10px auto',
  },
  '.logo': {
    width: '300px',
    display: 'block',
    margin: '20px auto',
  },
  '@media screen and (max-width:500px)': {
    '.icon': {
      width: '70px',
    },
    '.logo': {
      width: '200px',
    },
  },
}

const MORE_BOX_STYLES = {
  position: 'absolute',
  bottom: '0px',
  width: '100%',
  textAlign: 'center',
  margin: '20px auto',
  height: '40px',
  '.urls': {
    // transform: 'translateY(0px)',
    // opacity: 1,
    // transition: '.5s',
    // transitionDelay: '0.1s',
  },
  a: {
    margin: '0px 10px',
    // display: more ? 'block' : 'none',
  },
  img: {
    width: '30px',
    // maxHeight: '40px',
  },
}
const Card = ({ app, index }: { app: IApp; index: number }) => {
  const { i18n, t } = useTranslation()
  // const [more, setMore] = useState(false)
  const MARK_STYLES = {
    position: 'absolute',
    top: '35px',
    left: i18n.language !== 'ar' ? '45px' : 'auto',
    right: i18n.language !== 'ar' ? 'auto' : '45px',
    color: 'var(--main-color)',
    fontFamily: 'var(--main-font)',
    fontSize: '24px',
    fontWeight: i18n.language !== 'ar' ? '600' : 500,
  }
  // const SEE_MORE_STYLES = {
  //   display: more ? 'none' : 'flex',
  //   height: 'fit-content',
  //   alignItems: 'center',
  //   justifyContent: 'center',
  //   fontFamily: 'var(--main-font)',
  //   textTransform: 'uppercase',
  //   cursor: 'pointer',
  //   fontSize: i18n.language !== 'ar' ? '16px' : '18px',
  //   transition: '.2s',
  //   '.before , .after': {
  //     width: '50px',
  //     height: '1px',
  //     margin: '10px',
  //     background: '#2E1E41',
  //   },
  // }

  const [List, setList] = useState('')
  const [ListAr, setListAr] = useState('')

  // console.log(app)
  useEffect(() => {
    if (app.descriptionEn && app.descriptionAr) {
      setList(app.descriptionEn)
      setListAr(app.descriptionAr)
    }
  }, [app])
  return (
    <Grid item md={6} xs={12} sx={CARD_STYLES} className="card">
      {index % 2 === 0 && index > 1 && <div className="circle"></div>}
      <Box sx={MARK_STYLES}>{t(app.mark)}</Box>

      <Box>
        <Box>
          <Box
            sx={{
              minHeight: '280px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              '@media screen and (max-width:670px)': {
                minHeight: 'auto',
              },
            }}
          >
            <img
              src={`https://mfas-assets-public.s3.us-east-2.amazonaws.com/${
                i18n.language !== 'ar' ? app.iconEn : app.iconAr
              }`}
              alt={app.nameEn}
              className="icon"
            />

            <img
              src={`https://mfas-assets-public.s3.us-east-2.amazonaws.com/${
                i18n.language !== 'ar' ? app.logoEn : app.logoAr
              }`}
              alt={app.nameEn}
              className="logo"
            />
          </Box>
        </Box>
        <Typography
          sx={{
            fontSize: '20px',
            fontFamily: 'var(--main-font)',
            margin: '40px 10px 80px',
            fontWeight: 600,
            minHeight: '50px',
            '@media screen and (max-width:670px)': {
              minHeight: 'auto',
            },
          }}
        >
          {i18n.language !== 'ar'
            ? List.split('\n')?.map((el, index) => {
                return (
                  <Typography
                    sx={{
                      fontSize: '20px',
                      fontFamily: 'var(--main-font)',
                      fontWeight: 600,
                    }}
                    key={index}
                  >
                    {el}
                  </Typography>
                )
              })
            : ListAr.split('\n')?.map((el, index) => {
                return (
                  <Typography
                    sx={{
                      fontSize: '22px',
                      fontFamily: 'var(--main-font)',
                      fontWeight: 500,
                    }}
                    key={index}
                  >
                    {el}
                  </Typography>
                )
              })}
        </Typography>

        <Box sx={MORE_BOX_STYLES}>
          {/* {(app.webUrl ||
            app.googlePlayUrl ||
            app.youtubeUrl ||
            app.youtubeUrl) && (
            <Box sx={SEE_MORE_STYLES} onClick={() => setMore(true)}>
              <div className="before"></div>
              {t('see-more')}
              <div className="after"></div>
            </Box>
          )} */}
          <SeeMore app={app} />
        </Box>
      </Box>
    </Grid>
  )
}

export default Card
