import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AboutMe from './content/about-me/about-content'
import ContactMe from './content/contact-me/contact-content'
import Navbar from './navbar/Navbar'
import Sidebar from './sidebar/sidebar'

type Props = {
  topColor?: string | undefined
  bottomColor?: string | undefined
}
const Header = ({ topColor, bottomColor }: Props) => {
  const [content, setContent] = useState(0)
  const { i18n } = useTranslation()

  const [width, setWidth] = useState(document.body.clientWidth - 680)

  useEffect(() => {
    setWidth(document.body.clientWidth - 680)
  }, [i18n.language])
  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(document.body.clientWidth - 680)
    })
  }, [])

  const MAIN_SECTION = {
    width: '550px',
    position: 'absolute',
    transition: 'all 1.5s',
    // left: `${i18n.language === 'en' ? '100px' : 'auto'}`,
    // right: `${i18n.language === 'en' ? 'auto' : '100px'}`,
    transform: `${
      i18n.language === 'ar' ? `translateX(${width}px)` : 'translateX(100px)'
    }`,
    height: '0%',
    background: `linear-gradient(180deg, ${
      topColor ? topColor : ' var(--first-header-color)'
    } , ${bottomColor ? bottomColor : 'var(--second-header-color)'})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    color: '#fff',
    animation: 'height 1s forwards',
    animationDelay: '1s',

    '@media screen and (max-width: 900px)': {
      transform: 'none',
      left: i18n.language !== 'ar' ? 0 : 'auto',
      right: i18n.language === 'ar' ? 0 : 'auto',
    },

    '@media screen and (max-height: 700px)': {
      animation: 'heightMedium 1s forwards',
    },
    '@media screen and (max-width: 1050px)': {
      animation: 'height2 1s forwards',
      animationDelay: '1s',
    },
    '@media screen and (max-width: 670px)': {
      width: '100%',
      marginTop: '65px',
      animation: 'height3 1s forwards',

      // height: '900px !important',
    },
    // opacity: 0.2,
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: '100vh',
      }}
    >
      <Box className="header-content" sx={MAIN_SECTION}>
        <Navbar setContent={setContent} content={content} />
        {content === 0 && <AboutMe />}
        {content === 1 && <ContactMe />}
      </Box>
      <Sidebar topColor={topColor} bottomColor={bottomColor} />
    </div>
  )
}

export default Header
