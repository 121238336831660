import axios from 'axios'
import { API_BASE_URL } from '../domain'

const Api = axios.create({
  baseURL: API_BASE_URL,
})

const Admin = axios.create({
  baseURL: API_BASE_URL,
  headers: { 'Content-Type': 'multipart/form-data' },
})

Admin.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('token')
    const auth = token ? `Bearer ${token}` : ''
    config.headers = {
      Authorization: auth,
    }
    return config
  },
  (error) => Promise.reject(error)
)

export { Api, Admin }
