import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import authApi from 'api/api-handlers/auth'
import Btn from '../loading-button/Btn'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import VisibilityIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'

const Login = () => {
  const { enqueueSnackbar } = useSnackbar()

  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState<string>()
  const [password, setPassword] = useState<string>()

  const HandleLogin = async () => {
    if (!loading && username && password) {
      setLoading(true)
      authApi
        .sendMessage({ emailOrPhone: username, password: password })
        .then((res) => {
          setLoading(false)
          enqueueSnackbar(t('login'))
        })
        .catch((error) => {
          if (error.response) {
            setLoading(false)
            if (error.response.status === 401) {
              enqueueSnackbar(t('401'))
            } else if (error.response.status === 477) {
              enqueueSnackbar(t('477'))
            } else {
              enqueueSnackbar(t('wrong'))
            }
          }
        })
    }
  }
  const [show, setShow] = useState(false)
  return (
    <Box sx={LOGIN_STYLES}>
      <Typography component="h3">{t('login-control')}</Typography>
      <div>
        <Box>
          <Typography
            sx={{
              textAlign: i18n.language === 'en' ? 'left' : 'right',
              margin: '0px 10px 10px',
              fontFamily: 'var(--main-font)',
            }}
          >
            {t('emailOrPhone')}
          </Typography>
          <input
            name="emailOrPhone"
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </Box>
        <Box>
          <Typography
            sx={{
              textAlign: i18n.language !== 'ar' ? 'left' : 'right',
              margin: '0px 10px 10px',
              fontFamily: 'var(--main-font)',
            }}
          >
            {t('password')}
          </Typography>
          <Box sx={{ position: 'relative' }}>
            <Box
              sx={{
                position: 'absolute',
                right: '10px',
                top: '5px',
                background: '#fff',
                cursor: 'pointer',
              }}
              onClick={() => setShow(!show)}
            >
              {show ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </Box>
            <input
              name="password"
              type={show ? 'text' : 'password'}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Box>
        </Box>

        <Box marginTop="20px" marginBottom="40px">
          <Btn
            HandleClick={HandleLogin}
            text={t('login-btn')}
            type="main"
            loading={loading}
            classMore="w-100"
          />
        </Box>
      </div>
    </Box>
  )
}

export default Login

const LOGIN_STYLES = {
  width: '400px',
  textAlign: 'center',
  input: {
    width: '100%',
    padding: '5px 10px',
    marginBottom: '10px',
    fontSize: '18px',
    borderRadius: '27px',
  },
  h3: {
    fontSize: '28px',
    marginBottom: '40px',
    color: 'var(--main-color)',
    fontFamily: 'var(--main-font)',
    fontWeight: 900,
  },
  '.btn': {
    background: 'var(--main-color) !important',
    padding: '10px',
    color: '#fff',
    width: '100%',
    marginBottom: '20px',
  },
  '@media screen and (max-width:500px)': {
    width: '100%',
  },
}
