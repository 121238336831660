import React, { useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import ColorInput from './color-input'
import Header from 'components/Header/header'
import FontColorInput from './mainFontColor'
import MainImageBg from './mainImageBg'
import FooterColor from './footerColor'
import { Api } from 'api/api-handlers/Instance'
import API_ROUTES from 'api/routes'
import { useQuery } from '@tanstack/react-query'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { IconButton } from '@mui/material'

const TITLE_STYLES = {
  color: '#444',
  fontFamily: 'var(--main-font)',
  fontWeight: 500,
  fontSize: '30px',
  marginBottom: '0px',
  textAlign: 'center',
  '@media screen and (max-width:550px)': {
    fontSize: '24px',
    marginBottom: '20px',
  },
}

const GeneralControl = ({ setControl }: { setControl: any }) => {
  const { t, i18n } = useTranslation()
  const BACK_IMAGE = {
    transition: 'all 1.5s',
    backgroundPosition: `${i18n.language !== 'ar' ? 'top left' : 'top right'}`,
  }
  const [color, setColor] = useState({
    topColor: [],
    bottomColor: [],
  })
  const [colorFooter, setColorFooter] = useState({
    topColor: [],
    bottomColor: [],
  })

  const [fontColor, setFontColor] = useState(
    document.documentElement.style.getPropertyValue('--main-color')
  )

  const { data } = useQuery(['general'], async () => {
    let data = await Api.get(API_ROUTES.GENERAL_ROUTES.GET)
    return data.data.data
  })

  useEffect(() => {
    if (data) {
      setFontColor(data?.mainColor.value)
      setColor({
        topColor: JSON.parse(data.headerColor.value)[0],
        bottomColor: JSON.parse(data.headerColor.value)[1],
      })
      setColorFooter({
        topColor: JSON.parse(data.footerColor.value)[0],
        bottomColor: JSON.parse(data.footerColor.value)[1],
      })
    }
  }, [data])

  return (
    <Box
      sx={{
        width: '100vw',
        // '@media screen and (max-width:2000px)': {
        maxWidth: '2000px',

        //   margin: 'auto',
        //   overflow: 'hidden',
        // },
      }}
      //   width="100vw"
    >
      <header
        style={{
          position: 'fixed',
          height: '100vh',
          top: '0',
          width: '100vw',
          maxWidth: '2000px',
          // left: 0,
        }}
        className="header-modal"
      >
        <Box className="back-img" sx={BACK_IMAGE}></Box>
        <Header
          topColor={`rgb(${color.topColor[0]}, ${color.topColor[1]},${color.topColor[2]},${color.topColor[3]})`}
          bottomColor={`rgb(${color.bottomColor[0]}, ${color.bottomColor[1]},${color.bottomColor[2]},${color.bottomColor[3]})`}
        />
      </header>
      <Box
        sx={{
          position: 'absolute',
          top: '10px',
          background: '#fff',
          left: i18n.language === 'ar' ? '200px' : 'auto',
          right: i18n.language !== 'ar' ? '200px' : 'auto',
          padding: '20px',
          direction: i18n.dir(),
          '@media screen and (max-width:500px)': {
            left: 'auto',
            right: 'auto',
          },
        }}
      >
        <IconButton
          sx={{ position: 'absolute' }}
          onClick={() => setControl([-1, 1])}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography sx={TITLE_STYLES}> {t('admin.general-control')}</Typography>

        <Box margin="20px 0" padding="15px 5px 0px">
          <ColorInput color={color} setColor={setColor} />
        </Box>
        <Box margin="20px 0" padding="15px 5px 0px" borderTop="1px solid #ddd">
          <FooterColor color={colorFooter} setColor={setColorFooter} />
        </Box>

        <Box margin="20px 0" borderTop="1px solid #ddd" padding="15px 5px 0px">
          <FontColorInput color={fontColor} setColor={setFontColor} />
        </Box>

        <Box
          margin="20px 0 0px"
          borderTop="1px solid #ddd"
          padding="15px 5px 0px"
        >
          <MainImageBg />
        </Box>
      </Box>
    </Box>
  )
}

export default GeneralControl
