import React, { FC } from 'react'
import { IMessage } from 'interfaces/contact'
import MessageCard from './MessageCard'

interface IMessagesListProps {
  messages: IMessage[]
}

const MessageList: FC<IMessagesListProps> = ({ messages }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false)
    }

  return (
    <>
      {messages.map((message) => (
        <MessageCard
          key={message._id}
          message={message}
          expanded={expanded}
          handleChange={handleChange}
        />
      ))}
    </>
  )
}

export default MessageList
