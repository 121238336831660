import Logo from '../../logo/logo'
import { Box, Typography } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@tanstack/react-query'
import { Api } from 'api/api-handlers/Instance'
import API_ROUTES from 'api/routes'

const TEXT_STYLES = {
  color: '#fff',
  fontSize: '22px',
  fontWeight: '500',
  fontFamily: 'var(--main-font)',
  width: '60vw',
  maxWidth:'1000px',
  marginTop: '5px',
  height: 0,
  overflow: 'hidden',
  opacity: 0,
  transition: '1.5s',
  '&.active': {
    opacity: 1,
    height: '140px',
  },
  '@media screen and (max-width: 1050px)': {
    '&.active': {
      height: '150px',
    },
  },
  '@media screen and (max-width: 900px)': {
    '&.active': {
      height: '190px',
    },
  },
  '@media screen and (max-width: 670px)': {
    width: '100%',
    fontSize: '18px',
    '&.active': {
      height: '150px',
    },
  },
  '@media screen and (max-width: 530px)': {
    '&.active': {
      height: '200px',
      fontSize: '16px',
    },
  },
  '@media screen and (max-width: 350px)': {
    '&.active': {
      height: '220px',
    },
  },
}

const AboutMe = () => {
  const [open, setOpen] = useState(false)
  const { i18n, t } = useTranslation()

  const { data } = useQuery(['general'], async () => {
    let data = await Api.get(API_ROUTES.GENERAL_ROUTES.GET)
    return data.data.data
  })

  return (
    <Box
      className="fade-in"
      sx={{
        height: '73%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '@media screen and (min-height:700px)': {
          justifyContent: 'space-around',
          marginTop: '50px',
        },
      }}
    >
      <Logo width={45} />
      <Box
        sx={{
          padding: '0px 50px',
          marginTop: '50px',
          '@media screen and (max-width: 670px)': { padding: '0px 10px' },
        }}
        dir={i18n.dir()}
      >
        <Typography
          component="h1"
          variant="h1"
          sx={{
            color: 'var(--main-color)',
            opacity: '0.81',
            fontSize: '50px',
            fontWeight: i18n.language !== 'ar' ? 600 : 500,
            letterSpacing: i18n.language !== 'ar' ? '1px' : 0,
            fontFamily: 'var(--main-font)',

            '@media screen and (max-width:400px)': {
              fontSize: '40px',
            },
          }}
        >
          {t('profile-name')}
        </Typography>
        <Typography
          style={{
            color: '#fff',
            fontSize: i18n.language !== 'ar' ? '20px' : '35px',
            fontWeight: i18n.language !== 'ar' ? 600 : 500,
            fontFamily: 'var(--main-font)',
            textTransform: 'uppercase',
            cursor: 'pointer',
            marginTop: i18n.language !== 'ar' ? '10px' : 0,
          }}
          onClick={() => setOpen(!open)}
        >
          {t('about-me')}
        </Typography>

        <Typography
          className={open ? 'active' : ''}
          sx={TEXT_STYLES}
          fontSize={i18n.language !== 'ar' ? '18px' : '24px'}
        >
          {i18n.language !== 'ar'
            ? data?.aboutMeEn.value
            : data?.aboutMeAr.value}
        </Typography>
      </Box>
    </Box>
  )
}

export default AboutMe
