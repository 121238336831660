import { Box, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import ReasonCard from './reasons/ReasonCard'
import { useQueryClient } from '@tanstack/react-query'
import { Admin } from 'api/api-handlers/Instance'
import contactQueries from 'api/react-query/useContactQueries'
import API_ROUTES from 'api/routes'
import { IReason } from 'interfaces/contact'
import React, { useState } from 'react'
import InfiniteMessagesList from './messages/InfinitiMessagesList'
import Btn from '../loading-button/Btn'
import { useSnackbar } from 'notistack'

const TITLE_STYLES = {
  color: '#444',
  fontFamily: 'var(--main-font)',
  fontWeight: 500,
  fontSize: '34px',
  marginBottom: '0px',
  textAlign: 'center',
  '@media screen and (max-width:550px)': {
    fontSize: '24px',
    marginBottom: '20px',
  },
}
const ContactTabs = () => {
  const { t, i18n } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [value, setValue] = React.useState(`1`)

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const query = useQueryClient()

  const [offset] = useState(0)

  const [limit] = useState(100000)

  const { data, fetchNextPage, hasNextPage } = contactQueries.useMessageQuery({
    offset,
    limit,
  })

  const { data: reasons } = contactQueries.useReasonsQuery()
  const [loading, setLoading] = useState(false)

  const [reason, setReason] = useState({ nameEn: '', nameAr: '' })

  const HandleChange = (e: any) => {
    setReason({ ...reason, [e.target.name]: e.target.value })
  }

  const HandleAddReason = () => {
    setLoading(true)
    Admin.post(API_ROUTES.CONTACT_ROUTES.CREATE_POST_DELETE, reason)
      .then(async (res) => {
        await query.refetchQueries(['get-reasons'])
        setLoading(false)
        setReason({ nameEn: '', nameAr: '' })
        enqueueSnackbar(t('admin.success-added'))
      })
      .catch((err: any) => {
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear()
          window.location.reload()
        }
        setLoading(false)
        enqueueSnackbar(t('wrong'))
      })
  }

  const TEXT_BOX_STYLES = {
    textarea: {
      width: '100%',
      height: '70px',
      margin: '0',
      padding: '5px 10px',
      borderRadius: '15px',
      color: '#555',
      '&::placeholder': {
        fontSize: '16px',
        textAlign: i18n.language !== 'ar' ? 'left' : 'right',
        direction: i18n.dir(),
        fontFamily: 'var(--main-font)',
      },
    },
  }
  return (
    <Box>
      <Typography sx={TITLE_STYLES}>
        {t('admin.page-control')} {t('contacts')}
      </Typography>

      <TabContext value={value}>
        <Box dir={i18n.dir()}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: 'var(--main-color)',
              },
            }}
          >
            <Tab
              label={t('reasons')}
              value="1"
              sx={{
                fontFamily: 'var(--main-font)',
                fontSize: i18n.language !== 'ar' ? '18px' : '20px',
                textTransform: 'none',
                '&.Mui-selected': {
                  color: 'var(--main-color)',
                },
              }}
            />
            <Tab
              label={t('received-messages')}
              value="2"
              sx={{
                fontFamily: 'var(--main-font)',
                fontSize: i18n.language !== 'ar' ? '18px' : '20px',
                textTransform: 'none',

                '&.Mui-selected': {
                  color: 'var(--main-color)',
                },
              }}
            />
          </TabList>
        </Box>
        <Box
          sx={{
            height: '500px',
            overflowY: 'scroll',
            '@media screen and (max-width:900px)': { height: '100%' },
          }}
          className="scroll"
        >
          <TabPanel value="1">
            <Grid container spacing={2}>
              <Grid item md={5} xs={12} display="" sx={TEXT_BOX_STYLES}>
                <textarea
                  rows={2}
                  name="nameEn"
                  placeholder={t('admin.reason-en')}
                  style={{
                    fontSize: '16px',
                    fontFamily: 'var(--En-font)',
                    direction: 'ltr',
                  }}
                  value={reason.nameEn}
                  onChange={HandleChange}
                />
              </Grid>
              <Grid item md={5} xs={12} sx={TEXT_BOX_STYLES}>
                {/* <Typography>Ar</Typography> */}
                <textarea
                  rows={2}
                  name="nameAr"
                  placeholder={t('admin.reason-ar')}
                  style={{
                    direction: 'rtl',
                    fontSize: '18px',
                    fontFamily: 'var(--Ar-font)',
                  }}
                  value={reason.nameAr}
                  onChange={HandleChange}
                />
              </Grid>
              <Grid
                item
                md={2}
                sx={{
                  marginTop: '15px',
                  '@media screen and (max-width:900px)': { marginTop: 0 },
                }}
              >
                <Btn
                  text={t('admin.add')}
                  type="out-main-100"
                  HandleClick={HandleAddReason}
                  loading={loading}
                />
              </Grid>
            </Grid>
            {reasons?.map((el: IReason) => {
              return <ReasonCard key={el._id} reason={el} />
            })}
          </TabPanel>

          <TabPanel value="2">
            <InfiniteMessagesList
              data={data}
              fetchNextPage={fetchNextPage}
              hasNextPage={hasNextPage}
              limit={limit}
            />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  )
}

export default ContactTabs
