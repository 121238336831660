/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { InfiniteData } from '@tanstack/react-query'
import { IApp } from 'interfaces/products'
import AppsList from './app-list'

interface InfiniteAppsList {
  data:
    | InfiniteData<{
        data: IApp[]
        pageParam: any
      }>
    | undefined
  limit: number
  fetchNextPage: any
  hasNextPage: boolean | undefined
}

const InfiniteAppsList: FC<InfiniteAppsList> = ({
  data,
  limit,
  fetchNextPage,
  hasNextPage,
}) => {
  return (
    <InfiniteScroll
      style={{ overflow: 'hidden', minHeight: '300px' }}
      dataLength={data?.pages.length || limit}
      next={fetchNextPage}
      hasMore={!!hasNextPage}
      loader={<div>Loading ....</div>}
    >
      <>
        {data?.pages.map((page) => (
          <AppsList apps={page.data} key={page.pageParam} />
        ))}
      </>
    </InfiniteScroll>
  )
}

export default InfiniteAppsList
