/* eslint-disable react/jsx-no-duplicate-props */
import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import { useTranslation } from 'react-i18next'

type Props = {
  text: string
  type: string
  loading: boolean
  HandleClick?: () => void
  size?: number
  classMore?: string
  disabled?: boolean | undefined
}

const Btn = ({
  text,
  type,
  loading,
  HandleClick,
  size,
  classMore = '',
  disabled = false,
}: Props) => {
  const { i18n } = useTranslation()
  const BTN_STYLES = {
    color: type === 'main' ? '#fff' : 'var(--main-color)',
    background: type !== 'main' ? 'transparent' : 'var(--main-color)',
    border: type === 'main' ? 'none' : '1px solid var(--main-color)',
    borderRadius: type === 'main' ? '27px' : '27px',
    fontFamily: 'var(--main-font)',
    fontSize: i18n.language !== 'ar' ? '18px' : '20px',
    padding: '2px 25px',
    textTransform: 'capitalize',
    width: type === 'out-main-100' ? '100%' : 'auto',
    '&.w-100': {
      width: '100%',
      padding: '5px 25px',
      background: loading ? '#9ec8cc' : 'var(--main-color)',

      '&:active': {
        background: '#9ec8cc',
        opacity: '.8',
      },
    },
  }
  return (
    <Box>
      <Button
        sx={BTN_STYLES}
        disabled={loading && disabled}
        onClick={() => HandleClick?.()}
        className={classMore}
      >
        {!loading ? (
          <Typography
            sx={{
              fontFamily: 'var(--main-font)',
              fontSize: `${size}px`,
              whiteSpace: 'nowrap',
            }}
          >
            {text}
          </Typography>
        ) : (
          <>
            <CircularProgress
              size={15}
              style={{ position: 'absolute', color: 'var(--main-color)' }}
            />
            <Typography
              style={{
                opacity: 0.3,
                fontFamily: 'var(--main-font)',
                fontSize: `${size}px`,
                whiteSpace: 'nowrap',
              }}
            >
              {text}
            </Typography>
          </>
        )}
      </Button>
    </Box>
  )
}

export default Btn
