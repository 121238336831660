import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ChangeLang from './languages/lang'
import facebook from '../../../assets/images/facebook.png'
import instagram from '../../../assets/images/instagram.png'
import pinterest from '../../../assets/images/pinterest.png'
import { useQuery } from '@tanstack/react-query'
import { Api } from 'api/api-handlers/Instance'
import API_ROUTES from 'api/routes'

type Props = {
  topColor?: string | undefined
  bottomColor?: string | undefined
}

const Sidebar = ({ topColor, bottomColor }: Props) => {
  const { i18n } = useTranslation()
  const [width, setWidth] = useState(document.body.clientWidth - 110)
  window.onresize = async () => {
    setWidth(document.body.clientWidth - 110)
  }
  useEffect(() => {
    window.addEventListener('resize', () => {
      setWidth(document.body.clientWidth - 110)
    })
  }, [])

  useEffect(() => {
    setWidth(document.body.clientWidth - 110)
  }, [])

  useEffect(() => {
    setWidth(document.body.clientWidth - 110)
  }, [i18n.language])

  const SIDEBAR_STYLES = {
    transition: 'all 1.5s',
    position: 'absolute',
    // left: `${i18n.language === 'en' ? 'auto' : '0'}`,
    // right: `${i18n.language === 'en' ? '0' : 'auto'}`,
    transform: `${
      i18n.language === 'ar' ? 'translateX(0%)' : `translateX(${width}px)`
    }`,
    width: '100px',
    minHeight: '700px',
    height: '100vh',
    textAlign: 'center',
    borderLeft: `${i18n.language !== 'ar' ? '1px solid #bbb' : 'none'}`,
    borderRight: `${i18n.language === 'ar' ? '1px solid #bbb' : 'none'}`,
    borderBottom: 'none',
    borderTop: 'none',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    '@media screen and (max-width: 1050px) ': {
      height: '800px',
    },
    '@media screen and (max-width: 670px) ': {
      width: '100%',
      flexDirection: 'row',
      height: '65px',
      transform: 'none',
      border: 'none',
      minHeight: 'auto',

      // borderBottom: `${i18n.language === 'ar' ? '1px solid #bbb' : 'none'}`,
    },
  }
  const LETTER = {
    fontFamily: 'var(--general-font)',
    opacity: 0.25,
    fontSize: '35px',
    '@media screen and (max-width: 670px) ': {
      display: 'none',
    },
  }
  const SOCIAL_STYLES = {
    padding: '10px 3px 60px',
    img: {
      width: '18px',
      marginBottom: '20px',
    },
    '@media screen and (max-width: 670px) ': {
      display: 'flex',
      marginTop: '10px',
      a: {
        margin: '15px',
      },
    },
  }
  const city: Array<string> = ['L', 'O', 'N', 'D', 'O', 'N']
  const { data } = useQuery(['general'], async () => {
    let data = await Api.get(API_ROUTES.GENERAL_ROUTES.GET)
    return data.data.data
  })
  return (
    <Box sx={SIDEBAR_STYLES}>
      <ChangeLang topColor={topColor} bottomColor={bottomColor} />
      <Box className="scale" style={{ color: '#fff' }}>
        {city.map((letter, index) => {
          return (
            <Typography key={index} sx={LETTER}>
              {letter}
            </Typography>
          )
        })}
      </Box>
      <Box sx={SOCIAL_STYLES}>
        <Box>
          <a href={data?.facebook.value} target="_blank" rel="noreferrer">
            <img src={facebook} alt="facebook" style={{ width: '12px' }} />
          </a>
        </Box>
        <Box>
          <a href={data?.instagram.value} target="_blank" rel="noreferrer">
            <img src={instagram} alt="instagram" />
          </a>
        </Box>
        <Box>
          <a href={data?.pinterest.value} target="_blank" rel="noreferrer">
            <img src={pinterest} alt="pinterest" />
          </a>
        </Box>
      </Box>
    </Box>
  )
}

export default Sidebar
