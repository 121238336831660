import { Box, Typography } from '@mui/material'
import React from 'react'
import logo from '../../../assets/images/logo.svg'

const Logo = ({ width }: { width: number }) => {
  return (
    <Box width="100%" textAlign="center">
      <img
        alt="logo"
        src={logo}
        style={{ margin: 'auto', userSelect: 'none', width: `${width}%` }}
      />
      <Typography
        component="h1"
        variant="h1"
        fontSize="40px"
        style={{
          fontFamily: 'var(--En-font)',
          fontWeight: 600,
          letterSpacing: '1px',
          marginTop: '25px',
        }}
      >
        MFAS GROUP LIMITED
      </Typography>
    </Box>
  )
}

export default Logo
