import { Box, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import AddApp from './add-edit-product/add-app'
import DeleteApp from './delete-product/delete-app'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

const TITLE_STYLES = {
  color: '#444',
  fontFamily: 'var(--main-font)',
  fontWeight: 500,
  fontSize: '34px',
  marginBottom: '0px',
  textAlign: 'center',
  '@media screen and (max-width:550px)': {
    fontSize: '24px',
    marginBottom: '20px',
  },
}
const ProductControl = ({
  control,
  setControl,
}: {
  control: number
  setControl: any
}) => {
  const { t, i18n } = useTranslation()
  const [value, setValue] = React.useState(`${control}`)

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100%',
        padding: '40px 20px ',
        // overflowY: 'scroll',
        background: '#fff',
        position: 'absolute',
        top: 0,
        left: 0,
        maxWidth: '2000px',
      }}
    >
      <Typography sx={TITLE_STYLES}> {t('admin.product-control')}</Typography>

      <IconButton
        sx={{ position: 'absolute', top: '30px' }}
        onClick={() => setControl([-1, 1])}
      >
        <ArrowBackIcon />
      </IconButton>

      <TabContext value={value}>
        <Box dir={i18n.dir()}>
          <TabList
            variant="scrollable"
            scrollButtons
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              margin: '0px 25px',
              '& .MuiTabs-indicator': {
                backgroundColor: 'var(--main-color)',
              },
            }}
          >
            <Tab
              label={t('admin.add-product')}
              value="1"
              sx={{
                fontFamily: 'var(--main-font)',
                fontSize: i18n.language !== 'ar' ? '18px' : '20px',
                textTransform: 'none',
                '&.Mui-selected': {
                  color: 'var(--main-color)',
                },
              }}
            />
            <Tab
              label={t('admin.edit-product')}
              value="2"
              sx={{
                fontFamily: 'var(--main-font)',
                fontSize: i18n.language !== 'ar' ? '18px' : '20px',
                textTransform: 'none',

                '&.Mui-selected': {
                  color: 'var(--main-color)',
                },
              }}
            />
            <Tab
              label={t('admin.delete-product')}
              value="3"
              sx={{
                fontFamily: 'var(--main-font)',
                fontSize: i18n.language !== 'ar' ? '18px' : '20px',
                textTransform: 'none',

                '&.Mui-selected': {
                  color: 'var(--main-color)',
                },
              }}
            />
          </TabList>
        </Box>
        <TabPanel value="1">
          <AddApp type="add" />
        </TabPanel>
        <TabPanel value="2">
          <AddApp type="edit" />
        </TabPanel>
        <TabPanel value="3" sx={{ position: 'relative' }}>
          <Grid
            container
            style={{
              height: '100%',
              width: '',
              position: 'relative',
              zIndex: 2,
            }}
            dir={i18n.language !== 'ar' ? 'ltr' : 'rtl'}
          >
            <DeleteApp />
          </Grid>
        </TabPanel>
      </TabContext>
    </Box>
  )
}

export default ProductControl
