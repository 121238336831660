import Login from '../components/Admin/login/login'
import { Box, IconButton } from '@mui/material'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import HOME_ICON from '../assets/images/home.svg'
import Modal from '../components/Admin/modal'
import { useTranslation } from 'react-i18next'
import { Api } from 'api/api-handlers/Instance'
import API_ROUTES from 'api/routes'
import { useQuery } from '@tanstack/react-query'

const ADMIN_PAGE_STYLES = {
  minHeight: '100vh',
  maxWidth: '2000px',
  width: '100vw',
  overflowX: 'hidden',
}
const AdminPage = () => {
  const history = useNavigate()
  // useEffect(() => {
  //   if (!localStorage.getItem('admin-account')) {
  //     history('/')
  //   }
  // }, [history])

  const { i18n } = useTranslation()
  const GO_TO_HOME = {
    position: 'fixed',
    bottom: '20px',
    left: i18n.language !== 'ar' ? '20px' : 'auto',
    right: i18n.language !== 'ar' ? 'auto' : '20px',
    zIndex: 9,
    '@media screen and (min-width:2000px)':{
      left: 'auto',
      right: 'auto'
    }
  }

  const { data: color } = useQuery(['general'], async () => {
    let data = await Api.get(API_ROUTES.GENERAL_ROUTES.GET)
    return data.data.data
  })

  useEffect(() => {
    if (color) {
      document.documentElement.style.setProperty(
        '--main-color',
        color?.mainColor.value
      )
      document.documentElement.style.setProperty(
        '--background',
        `url(https://mfas-assets-public.s3.us-east-2.amazonaws.com/${color?.backgroundImage.value})`
      )

      document.documentElement.style.setProperty(
        '--first-header-color',
        `rgba(${JSON.parse(color?.headerColor.value)[0][0]},
        ${JSON.parse(color?.headerColor.value)[0][1]},
        ${JSON.parse(color?.headerColor.value)[0][2]},
        ${JSON.parse(color?.headerColor.value)[0][3]})`
      )

      document.documentElement.style.setProperty(
        '--second-header-color',
        `rgba(${JSON.parse(color?.headerColor.value)[1][0]},
        ${JSON.parse(color?.headerColor.value)[1][1]},
        ${JSON.parse(color?.headerColor.value)[1][2]},
        ${JSON.parse(color?.headerColor.value)[1][3]})`
      )

      document.documentElement.style.setProperty(
        '--first-footer-color',
        `rgb(${JSON.parse(color?.footerColor.value)[0][0]},
        ${JSON.parse(color?.footerColor.value)[0][1]},
        ${JSON.parse(color?.footerColor.value)[0][2]})`
      )

      document.documentElement.style.setProperty(
        '--second-footer-color',
        `rgb(${JSON.parse(color?.footerColor.value)[1][0]},
        ${JSON.parse(color?.footerColor.value)[1][1]},
        ${JSON.parse(color?.footerColor.value)[1][2]})`
      )
    }
  }, [color])

  return (
    <Box sx={ADMIN_PAGE_STYLES} >
      <Box
        className="back-img"
        sx={{
          height: '760px',
          minHeight: '100vh',
        }}
      />
      <Box style={{width:'100%'}} dir={i18n.dir()}>
        <IconButton sx={GO_TO_HOME} onClick={() => history('/')}>
          <img alt="home" src={HOME_ICON} style={{ width: '45px' }} />
        </IconButton>
      </Box>
     
      <Box
        sx={{
          position: 'absolute',
          zIndex: 3,
          width: '100%',
          height: '760px',
          background: 'rgba(0, 0, 0, 0.6)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: '2000px',
          minHeight: '100vh',
          overflowX: 'hidden',

          '@media screen and (max-width:900px)': {
            position: 'relative',
            height: '100%',
          },
        }}
        className="scroll1"
      >
        <Box
          sx={{
            background: '#fff',
            borderRadius: '100px',

            padding: '50px 70px 30px',
            transition: 'all .3s',
            '@media screen and (max-width:900px)': {
              borderRadius: '0',
              width: '100%',
              minHeight: '800px',
            },
            '@media screen and (max-width:670px)': {
              minHeight: '900px',
            },
            '@media screen and (max-width:550px)': { padding: '30px 10px' },
          }}
        >
          {localStorage.getItem('token') ? <Modal /> : <Login />}
        </Box>
      </Box>
    </Box>
  )
}

export default AdminPage
