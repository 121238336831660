import { Box } from '@mui/material'
import ContactTabs from './tabs-contact'

const ContactControl = () => {
  return (
    <Box
      sx={{
        width: '850px',

        '@media screen and (max-width:900px)': { width: '100%' },
      }}
    >
      <ContactTabs />
    </Box>
  )
}

export default ContactControl
