import React, { useState } from 'react'
import { Box, Grid } from '@mui/material'
import { IReason } from 'interfaces/contact'
import { Admin } from 'api/api-handlers/Instance'
import API_ROUTES from 'api/routes'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import Btn from '../../loading-button/Btn'
import { useSnackbar } from 'notistack'

type Props = {
  reason: IReason
}

const BOX_STYLES = {
  textarea: {
    border: 'none !important',
    borderRadius: '15px',
    background: '#f1f4f5',
    color: '#555',
    width: '100%',
    height: '70px',
    padding: '5px 10px',
    '&:focus': {
      outline: 'none !important',
      border: '1px solid #999 !important',
      background: '#fafafa',
    },
  },
}
const ReasonCard = ({ reason }: Props) => {
  const { t } = useTranslation()
  const query = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const [readOnly, setReadOnly] = useState(true)
  const [loading, setLoading] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)

  const [reasonEdit, setReasonEdit] = useState({
    nameAr: reason.nameAr,
    nameEn: reason.nameEn,
  })
  const HandleChange = (e: any) => {
    setReasonEdit({ ...reasonEdit, [e.target.name]: e.target.value })
  }
  const HandleEdit = () => {
    setLoading(true)
    Admin.patch(
      `${API_ROUTES.CONTACT_ROUTES.CREATE_POST_DELETE}/${reason._id}`,
      reasonEdit
    )
      .then(async (res) => {
        await query.refetchQueries(['get-reasons'])
        setLoading(false)
        enqueueSnackbar(t('admin.success-edit'))
      })
      .catch((err: any) => {
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear()
          window.location.reload()
        }
        setLoading(false)
        enqueueSnackbar(t('wrong'))
      })
  }

  const HandleDelete = () => {
    setLoadingDelete(true)
    Admin.delete(
      `${API_ROUTES.CONTACT_ROUTES.CREATE_POST_DELETE}/${reason._id}`
    )
      .then(async (res) => {
        await query.refetchQueries(['get-reasons'])
        setLoadingDelete(false)
        enqueueSnackbar(t('admin.success-delete'))
      })
      .catch((err: any) => {
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear()
          window.location.reload()
        }
        setLoadingDelete(false)
        enqueueSnackbar(t('wrong'))
      })
  }

  return (
    <Box sx={BOX_STYLES} margin="30px 0">
      <Grid container spacing={1}>
        <Grid item md={5} xs={12} display="">
          <textarea
            readOnly={readOnly}
            rows={2}
            name="nameEn"
            style={{
              fontSize: '16px',
              fontFamily: 'var(--En-font)',
              direction: 'ltr',
            }}
            value={reasonEdit.nameEn}
            onClick={() => setReadOnly(false)}
            onChange={HandleChange}
          />
        </Grid>
        <Grid item md={5} xs={12}>
          <textarea
            readOnly={readOnly}
            rows={2}
            name="nameAr"
            style={{
              direction: 'rtl',
              fontSize: '18px',
              fontFamily: 'var(--Ar-font)',
            }}
            onClick={() => setReadOnly(false)}
            value={reasonEdit.nameAr}
            onChange={HandleChange}
          />
        </Grid>
        <Grid
          item
          md={2}
          textAlign="center"
          sx={{ '@media screen and (max-width:900px)': { display: 'flex' } }}
        >
          <Box
            sx={{
              margin: '0',
              '@media screen and (max-width:900px)': { margin: '5px 10px' },
            }}
          >
            <Btn
              type="out-main"
              text={t('admin.save-edit')}
              loading={loading}
              HandleClick={HandleEdit}
              size={16}
            />
          </Box>
          <Box
            sx={{
              margin: '10px 0',
              '@media screen and (max-width:900px)': { margin: '5px 10px' },
            }}
          >
            <Btn
              type="out-main-100"
              text={t('admin.delete')}
              loading={loadingDelete}
              HandleClick={HandleDelete}
              size={16}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ReasonCard
