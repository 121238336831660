/* eslint-disable no-extend-native */
import { Box, Grid, Typography } from '@mui/material'
import { Admin } from 'api/api-handlers/Instance'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Btn from '../loading-button/Btn'
import { useSnackbar } from 'notistack'
import { useQueryClient } from '@tanstack/react-query'

type Prop = {
  setColor: any
  color: any
}
export default function ColorInput({ setColor, color }: Prop) {
  const Convert = (col: any) => {
    return col.substring(1).match(/.{1,2}/g)
  }

  const [top, setTop] = useState(
    `rgb(${color.topColor[0]}, ${color.topColor[1]},${color.topColor[2]})`
  )
  const [bottom, setBottom] = useState(
    `rgb(${color.bottomColor[0]}, ${color.bottomColor[1]},${color.bottomColor[2]})`
  )

  const [OpacityTop, setOpacityTop] = useState(color.topColor[3])
  const [OpacityBottom, setOpacityBottom] = useState(color.bottomColor[3])

  useEffect(() => {
    if (color) {
      setTop(
        `rgb(${color.topColor[0]}, ${color.topColor[1]},${color.topColor[2]})`
      )
      setBottom(
        `rgb(${color.bottomColor[0]}, ${color.bottomColor[1]},${color.bottomColor[2]})`
      )

      setOpacityTop(color.topColor[3])
      setOpacityBottom(color.bottomColor[3])
    }
  }, [color])

  const HandleChangeColor = async (e: any, type: string) => {
    if (e.target.name !== 'num1' && e.target.name !== 'num2') {
      let x = Convert(e.target.value)?.map((el: any) => parseInt(el, 16))
      if (type === 'top') {
        await setColor({
          ...color,
          topColor: [
            ...Convert(e.target.value)?.map((el: any) => parseInt(el, 16)),
            OpacityTop,
          ],
        })
        setTop(`rgb(${x[0]}, ${x[1]}, ${x[2]})`)
      }
      if (type === 'bottom') {
        await setColor({
          ...color,
          bottomColor: [
            ...Convert(e.target.value)?.map((el: any) => parseInt(el, 16)),
            OpacityBottom,
          ],
        })
      }
    } else {
      if (type === 'top') {
        setColor({
          ...color,
          topColor: [
            color.topColor[0],
            color.topColor[1],
            color.topColor[2],
            e.target.value,
          ],
        })
      }
      if (type === 'bottom') {
        setColor({
          ...color,
          bottomColor: [
            color.bottomColor[0],
            color.bottomColor[1],
            color.bottomColor[2],
            e.target.value,
          ],
        })
      }
    }
  }

  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)
  const query = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const HandleEdit = () => {
    if (!loading && color) {
      setLoading(true)
      Admin.patch(
        `https://hzou0g107c.execute-api.us-east-2.amazonaws.com/prod/general/63069c44e11d0e6ad4b6cbb2`,
        { value: JSON.stringify([color.topColor, color.bottomColor]) }
      )
        .then((res) => {
          setLoading(false)
          query.resetQueries(['general'])
          enqueueSnackbar(t('admin.success-edit'))
        })
        .catch((err: any) => {
          console.log(err)
          if (err.response.status === 401 || err.response.status === 403) {
            localStorage.clear()
            window.location.reload()
          }
          setLoading(false)
          enqueueSnackbar(t('wrong'))
        })
    }
  }
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom="10px"
      >
        <Typography
          sx={{
            fontFamily: 'var(--main-font)',
            fontSize: i18n.language !== 'ar' ? '18px' : '20px',
          }}
        >
          {t('admin.header-color')}
        </Typography>

        <Box onClick={() => HandleEdit()} textAlign="center">
          <Btn type="out-main" text={t('save')} loading={loading} />
        </Box>
      </Box>
      <Grid container spacing={2}>
        <Grid item md={6} display="flex">
          <label
            htmlFor="top-color"
            style={{
              padding: '5px',
              border: '1px solid #ccc',
            }}
          >
            <div
              style={{
                background: top,
                width: '80px',
                height: '20px',
              }}
            />
          </label>
          <input
            type="color"
            value={top}
            onChange={(e) => {
              setTop(e.target.value)
              HandleChangeColor(e, 'top')
            }}
            style={{ opacity: 0, position: 'absolute' }}
            id="top-color"
          />

          <input
            name="num1"
            type="number"
            min="0"
            max="1"
            step="0.05"
            value={OpacityTop}
            style={{ textAlign: 'center', border: '1px solid #ccc' }}
            onChange={(e) => {
              setOpacityTop(e.target.value)
              HandleChangeColor(e, 'top')
            }}
          />
        </Grid>
        <Grid item md={6} display="flex">
          <label
            htmlFor="bottom-color"
            style={{
              padding: '5px',
              border: '1px solid #ccc',
            }}
          >
            <div
              style={{
                background: bottom,
                width: '80px',
                height: '20px',
              }}
            />
          </label>
          <input
            id="bottom-color"
            type="color"
            value={bottom}
            onChange={(e) => {
              setBottom(e.target.value)
              HandleChangeColor(e, 'bottom')
            }}
            style={{ opacity: 0, position: 'absolute' }}
          />
          <input
            name="num2"
            type="number"
            min="0"
            max="1"
            step="0.05"
            style={{ textAlign: 'center', border: '1px solid #ccc' }}
            value={OpacityBottom}
            onChange={(e) => {
              setOpacityBottom(e.target.value)
              HandleChangeColor(e, 'bottom')
            }}
          />
        </Grid>
      </Grid>
    </Box>
  )
}
