import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem } from '@mui/material'
import { IApp } from 'interfaces/products'
import InfiniteScroll from 'react-infinite-scroll-component'
import { InfiniteData } from '@tanstack/react-query'

type Props = {
  data:
    | InfiniteData<{
        data: IApp[]
        pageParam: any
      }>
    | undefined
  limit: number
  fetchNextPage: any
  hasNextPage: boolean | undefined
  item: IApp | undefined
  popupState: any
  setItem: any
}

const List = ({
  data,
  item,
  popupState,
  setItem,
  limit,
  fetchNextPage,
  hasNextPage,
}: Props) => {
  const { i18n } = useTranslation()
  const ITEM_LIST = {
    width: '400px',
    '@media screen and (max-width:550px)': { width: '300px' },
    '@media screen and (max-width:350px)': { width: '270px' },
    direction: i18n.dir(),
    fontFamily: 'var(--main-font)',
    fontSize: i18n.language === 'en' ? '16px' : '18px',

    '&.active': {
      background: 'var(--main-color)',
    },
  }

  return (
    <>
      <InfiniteScroll
        style={{ overflow: 'hidden' }}
        dataLength={data?.pages.length || limit}
        next={fetchNextPage}
        hasMore={!!hasNextPage}
        loader={<div>Loading ....</div>}
      >
        {data?.pages.map((page, index) => (
          <Fragment key={index}>
            {page.data?.map((el: IApp) => {
              return (
                <MenuItem
                  key={el._id}
                  onClick={() => {
                    popupState.close()
                    setItem(el)
                  }}
                  sx={ITEM_LIST}
                  className={item?._id === el._id ? 'active' : 'item'}
                >
                  {i18n.language !== 'ar' ? el.nameEn : el.nameAr}
                </MenuItem>
              )
            })}
          </Fragment>
        ))}
      </InfiniteScroll>
    </>
  )
}

export default List
