import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import GetApps from '../get-apps/getApps'
import RadioInputs from './RadioInputs'
type Props = {
  type: string
  app: any
  HandleChange: (e: any) => void

  setStatus: any
  status: string
  setApp: any
  nameAr: boolean
  nameEn: boolean
  discAr: boolean
  discEn: boolean
  iconEn: boolean
  logoEn: boolean
  iconAr: boolean
  logoAr: boolean
  iconNameEn: string
  logoNameEn: string
  iconNameAr: string
  logoNameAr: string
  priority: boolean
}

const MainInputs = ({
  type,
  HandleChange,
  app,
  setStatus,
  status,
  setApp,
  nameAr,
  nameEn,
  discAr,
  discEn,
  iconEn,
  logoEn,
  iconNameEn,
  logoNameEn,
  iconAr,
  logoAr,
  iconNameAr,
  logoNameAr,
  priority,
}: Props) => {
  const { i18n, t } = useTranslation()

  return (
    <Grid
      container
      spacing={0}
      dir={i18n.dir()}
      sx={{
        'textarea , .lang': {
          '&::placeholder': {
            fontSize: '18px',
            textAlign: i18n.language !== 'ar' ? 'left' : 'right',
            direction: i18n.dir(),
            fontFamily: 'var(--main-font)',
          },
        },
      }}
    >
      <Grid item md={8} xs={12}>
        <Box
          sx={{ display: { md: 'flex', xs: 'block' }, marginBottom: '15px' }}
        >
          <Typography
            sx={{
              fontFamily: 'var(--main-font)',
              fontSize: i18n.language === 'ar' ? '20px' : '18px',
              width: i18n.language === 'ar' ? '190px' : '160px',
            }}
          >
            {type === 'add'
              ? t('admin.new-product-name')
              : t('admin.choose-edit-product')}
          </Typography>
          {type === 'add' && (
            <Grid
              container
              spacing={2}
              sx={{
                width: 'calc(100% - 190px)',
                '@media screen and (max-width:900px)': {
                  width: '100%',
                },
              }}
            >
              <Grid item lg={6} md={12} sm={6} xs={12}>
                <input
                  name="nameEn"
                  value={app.nameEn}
                  onChange={(e) => HandleChange(e)}
                  placeholder={t('admin.name-en')}
                  style={{
                    direction: 'ltr',
                    fontSize: '16px',
                    fontFamily: 'var(--En-font)',
                    padding: '8px',
                    borderColor: !nameEn ? 'red' : '#999',
                  }}
                  className="lang"
                />
              </Grid>
              <Grid item lg={6} md={12} sm={6} xs={12}>
                <input
                  name="nameAr"
                  value={app.nameAr}
                  onChange={(e) => HandleChange(e)}
                  placeholder={t('admin.name-ar')}
                  style={{
                    direction: 'rtl',
                    fontFamily: 'var(--Ar-font)',
                    borderColor: !nameAr ? 'red' : '#999',
                  }}
                  className="lang"
                />
              </Grid>
            </Grid>
          )}
          {type === 'edit' && <GetApps setItem={setApp} item={app} />}
        </Box>

        <Box sx={{ display: { md: 'flex', xs: 'block' }, marginBottom: '' }}>
          <Typography
            sx={{
              fontFamily: 'var(--main-font)',
              fontSize: i18n.language === 'ar' ? '20px' : '18px',
              width: i18n.language === 'ar' ? '190px' : '160px',
            }}
          >
            {t(`admin.${type}-icon`)}
          </Typography>
          <Box sx={{ display: { xs: 'block', xl: 'flex' } }}>
            <Box display="flex" margin="10px 0">
              <input
                name="icon-name"
                type="text"
                readOnly
                placeholder={t('icon-en')}
                value={iconNameEn}
                style={{ borderColor: !iconEn ? 'red' : '#999' }}
              />

              <input
                id="IconEn"
                name="iconEn"
                type="file"
                accept="image/png"
                onChange={(e) => HandleChange(e)}
                style={{ display: 'none' }}
              />
              <label
                htmlFor="IconEn"
                style={{
                  border: '1px solid #999',
                  borderRadius: '17px',
                  padding: '3px 7px',
                  height: 'fit-content',
                  fontSize: '16px',
                  fontFamily: 'var(--main-font)',
                  margin: '1px 5px',
                  color: '#999',
                  cursor: 'pointer',
                }}
              >
                {t('admin.browse')}
              </label>
            </Box>

            <Box display="flex" margin="10px 0">
              <input
                name="icon-name"
                type="text"
                readOnly
                placeholder={t('icon-ar')}
                value={iconNameAr}
                style={{ borderColor: !iconAr ? 'red' : '#999' }}
              />

              <input
                id="IconAr"
                name="iconAr"
                type="file"
                accept="image/png"
                onChange={(e) => HandleChange(e)}
                style={{ display: 'none' }}
              />
              <label
                htmlFor="IconAr"
                style={{
                  border: '1px solid #999',
                  borderRadius: '17px',
                  padding: '3px 7px',
                  height: 'fit-content',
                  fontSize: '16px',
                  fontFamily: 'var(--main-font)',
                  margin: '1px 5px',
                  color: '#999',
                  cursor: 'pointer',
                }}
              >
                {t('admin.browse')}
              </label>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ display: { md: 'flex', xs: 'block' }, marginBottom: '15px' }}
        >
          <Typography
            sx={{
              fontFamily: 'var(--main-font)',
              fontSize: i18n.language === 'ar' ? '20px' : '18px',
              width: i18n.language === 'ar' ? '190px' : '160px',
            }}
          >
            {t(`admin.${type}-logo`)}
          </Typography>
          <Box sx={{ display: { xs: 'block', xl: 'flex' } }}>
            <Box display="flex" margin="10px 0">
              <input
                name="logo-name"
                type="text"
                readOnly
                placeholder={t('logo-en')}
                value={logoNameEn}
                style={{ borderColor: !logoEn ? 'red' : '#999' }}
              />
              <input
                id="LogoEn"
                name="logoEn"
                type="file"
                accept="image/png"
                onChange={(e) => HandleChange(e)}
                style={{ display: 'none' }}
              />
              <label
                htmlFor="LogoEn"
                style={{
                  border: '1px solid #999',
                  borderRadius: '17px',
                  padding: '3px 7px',
                  height: 'fit-content',
                  fontSize: '16px',
                  fontFamily: 'var(--main-font)',
                  margin: '1px 5px',
                  color: '#999',
                  cursor: 'pointer',
                }}
              >
                {t('admin.browse')}
              </label>
            </Box>
            <Box display="flex" margin="10px 0">
              <input
                name="logo-name"
                type="text"
                readOnly
                placeholder={t('logo-ar')}
                value={logoNameAr}
                style={{ borderColor: !logoAr ? 'red' : '#999' }}
              />
              <input
                id="LogoAr"
                name="logoAr"
                type="file"
                accept="image/png"
                onChange={(e) => HandleChange(e)}
                style={{ display: 'none' }}
              />
              <label
                htmlFor="LogoAr"
                style={{
                  border: '1px solid #999',
                  borderRadius: '17px',
                  padding: '3px 7px',
                  height: 'fit-content',
                  fontSize: '16px',
                  fontFamily: 'var(--main-font)',
                  margin: '1px 5px',
                  color: '#999',
                  cursor: 'pointer',
                }}
              >
                {t('admin.browse')}
              </label>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{ display: { md: 'flex', xs: 'block' }, marginBottom: '15px' }}
        >
          <Typography
            whiteSpace="nowrap"
            sx={{
              fontFamily: 'var(--main-font)',
              fontSize: i18n.language === 'ar' ? '20px' : '18px',
              width: i18n.language === 'ar' ? '190px' : '160px',
            }}
          >
            {i18n.language === 'ar' && `${t(`admin.${type}`)} `}
            {t(`admin.disc-product`)}
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{
              width: 'calc(100% - 190px)',
              '@media screen and (max-width:900px)': {
                width: '100%',
              },
            }}
          >
            <Grid item lg={6} xs={12}>
              <textarea
                rows={2}
                name="descriptionEn"
                value={app.descriptionEn}
                onChange={(e) => HandleChange(e)}
                placeholder={t('admin.disc-en')}
                style={{
                  width: '100%',
                  direction: 'ltr',
                  fontFamily: 'var(--En-font)',
                  padding: '10px',
                  borderColor: !discEn ? 'red' : '#999',
                }}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <textarea
                style={{
                  width: '100%',
                  direction: 'rtl',
                  fontFamily: 'var(--Ar-font)',
                  borderColor: !discAr ? 'red' : '#999',
                }}
                rows={2}
                name="descriptionAr"
                value={app.descriptionAr}
                onChange={(e) => HandleChange(e)}
                placeholder={t('admin.disc-ar')}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item md={4} xs={12} style={{ position: 'relative' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            alignItems: { xs: 'center', lg: '' },
            justifyContent: 'space-around',
            '@media screen and (max-width:900px)': {
              // alignItems: 'inherit',
              flexDirection: 'row',
              justifyContent: 'space-between',
            },
            '@media screen and (max-width:650px)': {
              display: 'block',
            },
          }}
        >
          <Box
            sx={{
              textAlign: 'center',
              input: {
                width: '80px',
                '@media screen and (max-width:650px)': {
                  width: 'calc(100% - 150px)',
                },
              },
              '@media screen and (max-width:900px)': {
                display: 'flex',
                height: 'fit-content',
                alignItems: 'center',
              },
            }}
          >
            <Typography
              sx={{
                fontFamily: 'var(--main-font)',
                fontSize: i18n.language === 'ar' ? '20px' : '18px',
              }}
            >
              {t('admin.property')}
            </Typography>
            <input
              name="priority"
              type="number"
              style={{
                padding: '4px 10px',
                margin: '10px auto',
                textAlign: 'center',
                fontFamily: 'var(--En-font)',
                borderColor: !priority ? 'red' : '#999',
              }}
              value={app.priority}
              onChange={(e) => HandleChange(e)}
            />
          </Box>

          <RadioInputs status={status} setStatus={setStatus} />
        </Box>
      </Grid>
    </Grid>
  )
}

export default MainInputs
