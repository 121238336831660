import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useQuery } from '@tanstack/react-query'
import { Api } from 'api/api-handlers/Instance'
import API_ROUTES from 'api/routes'
import React from 'react'
import AboutMe from './about-me'
import AboutControl from './about-control'

const TITLE_STYLES = {
  color: '#444',
  fontFamily: 'var(--main-font)',
  fontWeight: 500,
  fontSize: '34px',
  marginBottom: '0px',
  textAlign: 'center',
  '@media screen and (max-width:550px)': {
    fontSize: '24px',
    marginBottom: '20px',
  },
}
const AboutTabs = () => {
  const { t, i18n } = useTranslation()
  const [value, setValue] = React.useState(`1`)

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  const { data } = useQuery(['general'], async () => {
    let data = await Api.get(API_ROUTES.GENERAL_ROUTES.GET)

    return data.data.data
  })
  return (
    <Box
      sx={{
        width: '850px',

        '@media screen and (max-width:900px)': { width: '100%' },
      }}
    >
      <Typography sx={TITLE_STYLES}>
        {t('admin.page-control')} {t('about')}
      </Typography>

      <TabContext value={value}>
        <Box dir={i18n.dir()}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tabs example"
            sx={{
              '& .MuiTabs-indicator': {
                backgroundColor: 'var(--main-color)',
              },
            }}
          >
            <Tab
              label={t('about')}
              value="1"
              sx={{
                fontFamily: 'var(--main-font)',
                fontSize: i18n.language !== 'ar' ? '18px' : '20px',
                textTransform: 'none',
                '&.Mui-selected': {
                  color: 'var(--main-color)',
                },
              }}
            />
            <Tab
              label={t('about-me')}
              value="2"
              sx={{
                fontFamily: 'var(--main-font)',
                fontSize: i18n.language !== 'ar' ? '18px' : '20px',
                textTransform: 'none',

                '&.Mui-selected': {
                  color: 'var(--main-color)',
                },
              }}
            />
          </TabList>
        </Box>
        <Box
          sx={{
            height: '500px',
            overflowY: 'scroll',
            '@media screen and (max-width:900px)': { height: '100%' },
          }}
          className="scroll"
        >
          <TabPanel value="1">
            <AboutControl data={data} />
          </TabPanel>

          <TabPanel value="2">
            <AboutMe data={data} />
          </TabPanel>
        </Box>
      </TabContext>
    </Box>
  )
}

export default AboutTabs
