import React from 'react'

const GoogleStore = ({
  width,
  marginTop,
}: {
  width: number
  marginTop: number
}) => {
  return (
    <svg
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width="47.902px"
      height="47.902px"
      viewBox="0 0 505.499 505.499" // style="enable-background:new 0 0 47.902 47.902;"
      xmlSpace="preserve"
      style={{ width: `${width}px`, marginTop: `${marginTop}px` }}
    >
      <g>
        <g>
          <g>
            <path
              d="M471.497,234.466l-92.082-53.135l-75.733,73.207l69.215,66.907l98.599-56.91c5.432-3.133,8.678-8.756,8.678-15.03
				C480.175,243.23,476.929,237.607,471.497,234.466z"
            />
            <polygon points="363.785,172.301 262.453,113.803 40.375,0 291.203,242.471 			" />
            <polygon points="44.063,505.499 262.834,384.988 357.268,330.473 291.203,266.604 			" />
            <polygon points="25.559,9.815 25.324,499.486 278.721,254.533 			" />
          </g>
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

export default GoogleStore
