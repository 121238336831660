import API_ROUTES from 'api/routes'
import { IApp, IGetAppPayload } from 'interfaces/products'
import { IResponse } from 'interfaces/response'
import { Api } from './Instance'

const getApps = async (payload?: IGetAppPayload) => {
  const { data } = await Api.get<IResponse<IApp[]>>(
    API_ROUTES.PRODUCT_ROUTES.GET,
    {
      params: {
        ...payload,
      },
    }
  )
  return data
}



const productsApi = {
  getApps,
}

export default productsApi
