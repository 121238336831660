import React from 'react'
import { useTranslation } from 'react-i18next'
import { MenuItem } from '@mui/material'
import { IReason } from 'interfaces/contact'

type Props = {
  data: any
  reason: IReason | undefined
  popupState: any
  setReason: any
  setNReason: any
}

const List = ({ data, reason, popupState, setReason, setNReason }: Props) => {
  const { i18n } = useTranslation()
  const ITEM_LIST = {
    width: '420px',
    direction: i18n.dir(),
    fontFamily: 'var(--main-font)',
    fontSize: i18n.language !== 'ar' ? '16px' : '18px',

    '&.active': {
      background: 'var(--main-color)',
    },
    '@media screen and (max-width:550px)': {
      width: '400px',
      fontSize: i18n.language !== 'ar' ? '12px' : '14px',
      fontWeight: '600',
    },
    '@media screen and (max-width:450px)': { width: '300px' },
    '@media screen and (max-width:350px)': { width: '270px' },
  }

  return (
    <>
      {data &&
        data.map((el: any) => {
          return (
            <MenuItem
              key={el._id}
              onClick={() => {
                popupState.close()
                setReason(el)
                setNReason(false)
              }}
              sx={ITEM_LIST}
              className={reason?._id === el._id ? 'active' : 'item'}
            >
              {i18n.language !== 'ar' ? el.nameEn : el.nameAr}
            </MenuItem>
          )
        })}
    </>
  )
}

export default List
