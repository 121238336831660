import { IGetMessagesPayload, IMessage, IReason } from 'interfaces/contact'
import { IResponse } from 'interfaces/response'
import API_ROUTES from '../routes'
import { Admin, Api } from './Instance'

const sendMessage = async (payload: unknown) => {
  const { data } = await Api.post(API_ROUTES.MESSAGES_ROUTES.CONTACT, payload)
  return data
}

const getReasons = async () => {
  const { data } = await Api.get<IResponse<IReason[]>>(
    API_ROUTES.CONTACT_ROUTES.GET_REASONS
  )
  return data
}

const getMessages = async (payload?: IGetMessagesPayload) => {
  const { data } = await Admin.get<IResponse<IMessage[]>>(
    API_ROUTES.MESSAGES_ROUTES.GET,
    {
      params: {
        ...payload,
      },
    }
  )
  return data
}
const contactApi = {
  sendMessage,
  getReasons,
  getMessages,
}

export default contactApi
