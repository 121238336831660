import Logo from '../../logo/logo'
import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import Dropdown from './dropdown/dropdown'
import { useTranslation } from 'react-i18next'
import { Api } from '../../../../api/api-handlers/Instance'
import API_ROUTES from 'api/routes'
import Btn from 'components/Admin/loading-button/Btn'
import { useSnackbar } from 'notistack'
import { useQueryClient } from '@tanstack/react-query'
import { IReason } from 'interfaces/contact'

const ContactMe = () => {
  const { i18n, t } = useTranslation()
  const INPUT_BOX = {
    input: {
      width: '100%',
      background: 'transparent !important',
      border: '1px solid #707070 ',
      padding: '7px 10px',
      marginBottom: '15px',
      color: '#fff',
      fontFamily: 'var(--main-font)',
      fontSize: '18px',
      borderRadius: '3px',
      '&::focus': {
        border: '1px solid #707070',
        outline: 'none !important',
      },
      '&::placeholder': {
        color: '#bbb',
        textAlign: i18n.language !== 'ar' ? 'left' : 'right',
      },
    },
    textarea: {
      width: '100%',
      background: 'transparent !important',
      border: '1px solid #707070',
      padding: '7px 10px',
      color: '#fff',
      fontFamily: 'var(--main-font)',
      fontSize: '18px',
      borderRadius: '3px',
      marginBottom: '10px',

      '&::placeholder': {
        color: '#bbb',
        textAlign: i18n.language !== 'ar' ? 'left' : 'right',
      },
    },
  }

  const [reason, setReason] = useState<IReason>()
  const [request, setRequest] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const [nReason, setNReason] = useState(false)
  const [nName, setNName] = useState(false)
  const [nEmail, setNEmail] = useState(false)
  const [nMessage, setNMessage] = useState(false)

  const query = useQueryClient()

  const HandleChange = (e: any) => {
    setRequest({ ...request, [e.target.name]: e.target.value })
    if (e.target.name === 'name') {
      if (e.target.value.length < 1) setNName(true)
      else setNName(false)
    }
    if (e.target.name === 'email') {
      if (e.target.value.length < 1) setNEmail(true)
      else setNEmail(false)
    }
    if (e.target.name === 'message') {
      if (e.target.value.length < 1) setNMessage(true)
      else setNMessage(false)
    }
  }

  const Validation = () => {
    if (!reason?._id) setNReason(true)
    else setNReason(false)
    if (!request.name) setNName(true)
    else setNName(false)
    if (!request.email) setNEmail(true)
    else setNEmail(false)
    if (!request?.message) setNMessage(true)
    else setNMessage(false)
  }

  const HandleSend = async () => {
    await Validation()
    if (reason?._id && request.name && request.email && request.message) {
      setLoading(true)
      await Api.post(
        `${API_ROUTES.MESSAGES_ROUTES.CONTACT}/${reason?._id}`,
        request
      )
        .then((res) => {
          setLoading(false)
          enqueueSnackbar(t('admin.success-send'))
          query.refetchQueries(['get-infinite-messages'])
          setRequest({})
        })
        .catch((err) => {
          setLoading(false)
          enqueueSnackbar(t('wrong'))
        })
    }
  }

  const REQUEST_STYLES = {
    color: '#d26d6d',
    fontSize: '14px',
    fontFamily: 'var(--main-font)',
    margin: '-10px 2px 10px',
  }
  return (
    <Box
      className="fade-in"
      marginTop="10px"
      sx={{
        height: '85%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        '@media screen and (min-height:750px)': {
          justifyContent: 'space-around',
        },
      }}
    >
      <Box>
        <Logo width={28} />
        <Box
          sx={{
            width: '420px',
            margin: '40px auto 0',
            '@media screen and (max-width:550px)': { width: '400px' },
            '@media screen and (max-width:450px)': { width: '300px' },
            '@media screen and (max-width:350px)': { width: '270px' },
          }}
        >
          <Dropdown
            setReason={setReason}
            reason={reason}
            setNReason={setNReason}
            nReason={nReason}
          />
          <Box sx={INPUT_BOX}>
            <input
              name="name"
              placeholder={t('name')}
              dir={i18n.dir()}
              onChange={HandleChange}
              required
              value={request.name}
            />
            {nName && (
              <Typography sx={REQUEST_STYLES}>{t('required')}</Typography>
            )}
          </Box>
          <Box sx={INPUT_BOX}>
            <input
              name="email"
              placeholder={t('email')}
              dir="ltr"
              onChange={HandleChange}
              required
              value={request.email}
            />
            {nEmail && (
              <Typography sx={REQUEST_STYLES}>{t('required')}</Typography>
            )}
          </Box>
          <Box sx={INPUT_BOX}>
            <textarea
              name="message"
              placeholder={t('message')}
              rows={3}
              dir={i18n.dir()}
              onChange={HandleChange}
              required
              value={request.message}
            />
            {nMessage && (
              <Typography sx={REQUEST_STYLES}>{t('required')}</Typography>
            )}
          </Box>
          <Box textAlign="center" margin="20px 0">
            <Btn
              HandleClick={HandleSend}
              type="out-main"
              text={t('send-message')}
              loading={loading}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ContactMe
