import { Box, Typography } from '@mui/material'
import { Admin } from 'api/api-handlers/Instance'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Btn from '../loading-button/Btn'
import { useSnackbar } from 'notistack'
import { useQueryClient } from '@tanstack/react-query'

type Prop = {
  setColor: any
  color: any
}
export default function FontColorInput({ setColor, color }: Prop) {
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)

  const query = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const HandleEdit = () => {
    if (color && !loading) {
      setLoading(true)
      Admin.patch(
        `https://hzou0g107c.execute-api.us-east-2.amazonaws.com/prod/general/6302a0a891721df13c30b240`,
        { value: color }
      )
        .then((res) => {
          setLoading(false)
          query.resetQueries(['general'])
          enqueueSnackbar(t('admin.success-edit'))
        })
        .catch((err: any) => {
          if (err.response.status === 401 || err.response.status === 403) {
            localStorage.clear()
            window.location.reload()
          }
          console.log(err)
          setLoading(false)
          enqueueSnackbar(t('wrong'))
        })
    }
  }
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" alignItems="center">
        <Typography
          sx={{
            fontFamily: 'var(--main-font)',
            fontSize: i18n.language !== 'ar' ? '18px' : '20px',
          }}
        >
          {t('admin.main-font')}
        </Typography>
        <label
          htmlFor="top-color"
          style={{
            margin: '0px 5px',
            padding: '5px',
            border: '1px solid #ccc',
          }}
        >
          <div
            style={{
              background: color,
              width: '50px',
              height: '20px',
            }}
          />
        </label>

        <input
          // style={{ margin: '0px 5px' }}
          type="color"
          style={{ opacity: 0, position: 'absolute' }}
          value={color}
          onChange={(e) => {
            setColor(e.target.value)
            document.documentElement.style.setProperty(
              '--main-color',
              e.target.value
            )
          }}
        />
      </Box>

      <Box onClick={() => HandleEdit()} textAlign="center">
        <Btn type="out-main" text={t('save')} loading={loading} />
      </Box>
    </Box>
  )
}
