import Btn from '../../loading-button/Btn'
import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import MainInputs from './MainInputs'
import UrlsInputs from './UrlsInputs'
import { Admin } from 'api/api-handlers/Instance'
import API_ROUTES from 'api/routes'
import { useQueryClient } from '@tanstack/react-query'
// import UrlsInputs from './urlsInputs'
import { useSnackbar } from 'notistack'
import { IApp } from 'interfaces/products'
import imageCompression from 'browser-image-compression'

type Props = {
  type: string
}

const CONTENT_STYLES = {
  input: {
    borderRadius: '27px',
    border: '1px solid #999',
    padding: '3px 5px',
    fontSize: '18px',
    fontFamily: 'var(--main-font)',
    color: '#555',
    // '@media screen and (max-width:900px)': {
    width: '100%',
    // },
  },
  textarea: {
    maxWidth: '100%',
    minWidth: '100%',
    borderRadius: '15px',
    border: '1px solid #999',
    padding: '3px 5px',
    fontSize: '18px',
    fontFamily: 'var(--main-font)',
    color: '#555',
    '@media screen and (max-width:900px)': {
      width: '100%',
    },
  },
  '@media screen and (max-width:1200px)': {
    // height: '500px',
    // overflowY: 'scroll',
  },

  '@media screen and (max-width:900px)': { height: '100%' },
}
const AddApp = ({ type }: Props) => {
  const { t, i18n } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const [loading, setLoading] = useState(false)

  const createFormDate = (data: object) => {
    let formData = new FormData()

    for (const [key, value] of Object.entries(data)) {
      if (value === undefined || value === null) continue

      if (Array.isArray(value)) {
        for (const item of value) {
          formData.append(key, item)
        }
      } else formData.append(key, value)
    }
    return formData
  }

  const [app, setApp] = useState<IApp>({
    nameAr: '',
    nameEn: '',
    descriptionAr: '',
    descriptionEn: '',
    iconEn: undefined,
    iconAr: undefined,
    logoEn: undefined,
    logoAr: undefined,
    mark: 'soon',
    priority: Number(null),
  })
  const [editApp, setEditApp] = useState({})
  const [status, setStatus] = useState(app.mark)

  const [iconNameEn, setIconNameEn] = useState('')
  const [logoNameEn, setLogoNameEn] = useState('')
  const [iconNameAr, setIconNameAr] = useState('')
  const [logoNameAr, setLogoNameAr] = useState('')
  const [nameAr, setNameAr] = useState(true)
  const [nameEn, setNameEn] = useState(true)
  const [discAr, setDiscAr] = useState(true)
  const [discEn, setDiscEn] = useState(true)
  const [priority, setPriority] = useState(true)

  const [iconEn, setIconEn] = useState(true)
  const [iconAr, setIconAr] = useState(true)
  const [logoEn, setLogoEn] = useState(true)
  const [logoAr, setLogoAr] = useState(true)

  const Validation = async (inputs: IApp) => {
    if (inputs.nameAr.length <= 0) setNameAr(false)
    else setNameAr(true)
    if (inputs.nameEn.length <= 0) setNameEn(false)
    else setNameEn(true)
    if (inputs.descriptionAr.length <= 0) setDiscAr(false)
    else setDiscAr(true)
    if (inputs.descriptionEn.length <= 0) setDiscEn(false)
    else setDiscEn(true)
    if (inputs.priority <= 0) setPriority(false)
    else setPriority(true)
    if (iconNameEn.length <= 0 && !inputs.iconEn) setIconEn(false)
    else setIconEn(true)
    if (iconNameAr.length <= 0 && !inputs.iconAr) setIconAr(false)
    else setIconAr(true)
    if (logoNameEn.length <= 0 && !inputs.logoEn) setLogoEn(false)
    else setLogoEn(true)
    if (logoNameAr.length <= 0 && !inputs.logoAr) setLogoAr(false)
    else setLogoAr(true)
  }

  async function handleImageUpload(e: any) {
    const imageFile = e.target.files[0]
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    }
    try {
      const compressedFile = await imageCompression(imageFile, options)
      await setApp({ ...app, [e.target.name]: compressedFile })
      await setEditApp({ ...editApp, [e.target.name]: compressedFile })
    } catch (error) {
      console.log(error)
    }
  }

  const HandleChange = async (e: any) => {
    if (e.target.files) {
      if (e.target.name === 'iconEn') {
        setIconNameEn(e.target.files[0].name)
        handleImageUpload(e)
      }
      if (e.target.name === 'iconAr') {
        setIconNameAr(e.target.files[0].name)
        handleImageUpload(e)
      }
      if (e.target.name === 'logoEn') {
        handleImageUpload(e)

        setLogoNameEn(e.target.files[0].name)
      }
      if (e.target.name === 'logoAr') {
        setLogoNameAr(e.target.files[0].name)
        handleImageUpload(e)
      }
      // setApp({ ...app, [e.target.name]: e.target.files[0] })
      // setEditApp({ ...editApp, [e.target.name]: e.target.files[0] })
    } else {
      if (e.target.name === 'priority') {
        setApp({ ...app, [e.target.name]: Number(e.target.value) })
        setEditApp({ ...editApp, [e.target.name]: Number(e.target.value) })
      } else {
        // if (e.target.value.length >= 0)
        setApp({ ...app, [e.target.name]: e.target.value })
        setEditApp({ ...editApp, [e.target.name]: e.target.value })
      }

      // console.log({...app, [e.target.name]: e.target.value })
    }

    if (e.target.name === 'nameAr') {
      if (e.target.value.length > 1) setNameAr(true)
      else setNameAr(false)
    }
    if (e.target.name === 'nameEn') {
      if (e.target.value.length > 1) setNameEn(true)
      else setNameEn(false)
    }
    if (e.target.name === 'descriptionAr') {
      if (e.target.value.length > 1) setDiscAr(true)
      else setDiscAr(false)
    }
    if (e.target.name === 'descriptionEn') {
      if (e.target.value.length > 1) setDiscEn(true)
      else setDiscEn(false)
    }
    if (e.target.name === 'iconEn') {
      if (e.target.files[0]) setIconEn(true)
      else setIconEn(false)
    }
    if (e.target.name === 'logoEn') {
      if (e.target.files[0]) setLogoEn(true)
      else setLogoEn(false)
    }
    if (e.target.name === 'iconAr') {
      if (e.target.files[0]) setIconAr(true)
      else setIconAr(false)
    }
    if (e.target.name === 'logoAr') {
      if (e.target.files[0]) setLogoAr(true)
      else setLogoAr(false)
    }
    if (e.target.name === 'priority') {
      if (Number(e.target.value) > 0) setPriority(true)
      else setPriority(false)
    }
  }
  const query = useQueryClient()
  const HandleClick = async () => {
    Validation(app)
    if (
      type === 'add' &&
      app.nameAr.length > 0 &&
      app.nameEn.length > 0 &&
      app.descriptionAr.length > 0 &&
      app.descriptionEn.length > 0 &&
      app.priority > 0 &&
      app.iconEn &&
      app.iconAr &&
      app.logoEn &&
      app.logoAr
    ) {
      console.log({ ...app, mark: status })
      setLoading(true)

      let formData = createFormDate({ ...app, mark: status })
      await Admin.post(API_ROUTES.PRODUCT_ROUTES.CREATE_POST_DELETE, formData)
        .then(async (res) => {
          await query.refetchQueries(['get-infinite-apps'])
          enqueueSnackbar(t('added'))
          setLoading(false)
          setApp({
            nameAr: '',
            nameEn: '',
            descriptionAr: '',
            descriptionEn: '',
            iconEn: undefined,
            logoEn: undefined,
            iconAr: undefined,
            logoAr: undefined,
            mark: 'soon',
            priority: Number(null),
            webUrl: '',
            appleStoreUrl: '',
            googlePlayUrl: '',
            youtubeUrl: '',
            facebookUrl: '',
            linkedInUrl: '',
            pinterestUrl: '',
            instagramUrl: '',
            twitterUrl: '',
            tiktokUrl: '',
            snapchatUrl: '',
            behanceUrl: '',
          })
          setIconNameEn('')
          setLogoNameEn('')
          setIconNameAr('')
          setLogoNameAr('')
          setStatus('soon')
        })
        .catch((err) => {
          console.log(err)
          if (err.response.status === 401 || err.response.status === 403) {
            localStorage.clear()
            window.location.reload()
          }
          setLoading(false)
          enqueueSnackbar(t('wrong'))
        })
    } else if (
      type === 'edit' &&
      app._id &&
      app.nameAr.length > 0 &&
      app.nameEn.length > 0 &&
      app.descriptionAr.length > 0 &&
      app.descriptionEn.length > 0 &&
      app.priority > 0 &&
      app.iconEn &&
      app.iconAr &&
      app.logoEn &&
      app.logoAr
    ) {
      setLoading(true)

      let formData = createFormDate({ ...editApp, mark: status })
      await Admin.patch(
        `${API_ROUTES.PRODUCT_ROUTES.CREATE_POST_DELETE}/${app._id}`,
        formData
      )
        .then(async (res) => {
          await query.refetchQueries(['get-infinite-apps'])
          enqueueSnackbar(t('edited'))
          setLoading(false)
          setEditApp({})
        })
        .catch((err: any) => {
          setLoading(false)
          enqueueSnackbar(t('wrong'))
          if (err.response.status === 401 || err.response.status === 403) {
            localStorage.clear()
            window.location.reload()
          }
        })
    }
  }

  const SetItem = async (item: IApp) => {
    // if(type ==='edit')
    await setApp({
      nameAr: '',
      nameEn: '',
      descriptionAr: '',
      descriptionEn: '',
      iconEn: undefined,
      logoEn: undefined,
      iconAr: undefined,
      logoAr: undefined,
      mark: 'soon',
      priority: Number(null),
      webUrl: '',
      appleStoreUrl: '',
      googlePlayUrl: '',
      youtubeUrl: '',
      facebookUrl: '',
      linkedInUrl: '',
      pinterestUrl: '',
      instagramUrl: '',
      twitterUrl: '',
      tiktokUrl: '',
      snapchatUrl: '',
      behanceUrl: '',
    })
    setApp(item)
    setStatus(item.mark)
    setIconNameEn(item.iconEn)
    setLogoNameEn(item.logoEn)
    setIconNameAr(item.iconAr)
    setLogoNameAr(item.logoAr)
  }

  return (
    <Box sx={CONTENT_STYLES} className="scroll">
      <MainInputs
        type={type}
        HandleChange={HandleChange}
        app={app}
        setStatus={setStatus}
        status={status}
        setApp={SetItem}
        nameAr={nameAr}
        nameEn={nameEn}
        discAr={discAr}
        discEn={discEn}
        priority={priority}
        iconEn={iconEn}
        logoEn={logoEn}
        iconAr={iconAr}
        logoAr={logoAr}
        iconNameEn={iconNameEn}
        logoNameEn={logoNameEn}
        iconNameAr={iconNameAr}
        logoNameAr={logoNameAr}
      />

      <UrlsInputs HandleChange={HandleChange} app={app} />
      <Box
        sx={{
          display: { md: 'flex', xs: 'block' },
          justifyContent: 'space-between',
          height: 'fit-content',
          alignItems: 'center',
          marginTop: '25px',
        }}
        dir={i18n.dir()}
      >
        <Typography
          sx={{
            fontFamily: 'var(--main-font)',
            fontSize: i18n.language === 'ar' ? '18px' : '16px',
          }}
        >
          {t('admin.note')}
        </Typography>
        <Box
          sx={{
            '@media screen and (max-width:900px)': {
              textAlign: 'center',
              marginTop: '20px',
            },
          }}
        >
          <Btn
            type="out-main"
            text={type === 'add' ? t('admin.add') : t('admin.edit')}
            loading={loading}
            HandleClick={HandleClick}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default AddApp
