import { Box, IconButton } from '@mui/material'
import { IMessage } from 'interfaces/contact'
import React, { useState } from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/Delete'
import { styled } from '@mui/material/styles'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { Admin } from 'api/api-handlers/Instance'
import API_ROUTES from 'api/routes'
import { useQueryClient } from '@tanstack/react-query'
import { useTranslation } from 'react-i18next'
import EmailIcon from '@mui/icons-material/Email'
import BorderColorIcon from '@mui/icons-material/BorderColor'

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}))

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}))

type Props = {
  message: IMessage
  handleChange: any
  expanded: string | boolean
}

const MessageCard = ({ message, handleChange, expanded }: Props) => {
  const { i18n } = useTranslation()
  const query = useQueryClient()
  const [deleteLoader, setDeleteLoader] = useState(false)

  const HandleDelete = (id: string) => {
    setDeleteLoader(true)
    Admin.delete(`${API_ROUTES.MESSAGES_ROUTES.DELETE}/${id}`)
      .then(async (res) => {
        await query.refetchQueries(['get-infinite-messages'])
        setDeleteLoader(false)
      })
      .catch((err: any) => {
        console.log(err)
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear()
          window.location.reload()
        }
      })
  }

  return (
    <div style={{ display: '', position: 'relative' }} key={message._id}>
      {deleteLoader ? (
        <Box
          sx={{
            svg: {
              color: 'var( --main-color)',
            },
          }}
          style={{
            position: 'absolute',
            zIndex: 11,
            right: 0,
            height: '50px',
            padding: '10px',
          }}
        >
          <CircularProgress size={25} />
        </Box>
      ) : (
        <IconButton
          onClick={() => HandleDelete(message._id)}
          style={{
            position: 'absolute',
            zIndex: 11,
            top: '5px',
            width: 'fit-content',
            left: i18n.language === 'ar' ? 0 : 'auto',
            right: i18n.language !== 'ar' ? 0 : 'auto',
          }}
        >
          <DeleteIcon />
        </IconButton>
      )}
      <Accordion
        dir={i18n.dir()}
        expanded={expanded === `panel${message._id}`}
        onChange={handleChange(`panel${message._id}`)}
      >
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          style={{ background: '#00b1c514' }}
        >
          <Typography
            style={{ paddingRight: '20px', fontFamily: 'var(--main-font)' }}
          >
            {message.name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography
            sx={{
              color: 'var(--main-color)',
              cursor: 'pointer',
              fontFamily: 'var(--main-font)',
              display: 'flex',
            }}
            onClick={() => window.open(`mailto:${message.email}`)}
          >
            <EmailIcon
              style={{
                color: '#999',
                position: 'relative',
                width: '45px',
                opacity: 0.5,
              }}
            />
            <Box sx={{ '&:hover': { fontWeight: 600 } }}>{message.email}</Box>
          </Typography>
          <Typography sx={{ fontFamily: 'var(--main-font)', color: '#444' }}>
            <BorderColorIcon
              style={{
                color: '#999',
                position: 'relative',
                top: '5px',
                width: '45px',
                opacity: 0.5,
              }}
            />
            {i18n.language !== 'ar'
              ? message.reason.nameEn
              : message.reason.nameAr}
          </Typography>
          <Typography
            sx={{
              margin: '20px 10px',
              fontFamily: 'var(--main-font)',
              fontSize: '18px',
            }}
          >
            {message.message}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default MessageCard
