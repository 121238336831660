import React from 'react'

const SnapChat = ({
  width,
  marginTop,
}: {
  width: number
  marginTop: number
}) => {
  return (
    <svg
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 504 504"
      role="img"
      style={{ width: `${width}px`, marginTop: `${marginTop}px` }}
    >
      <g>
        <g>
          <path
            d="M377.6,0H126C56.8,0,0,56.8,0,126.4V378c0,69.2,56.8,126,126,126h251.6c69.6,0,126.4-56.8,126.4-126.4V126.4
			C504,56.8,447.2,0,377.6,0z M402.8,334.8c-1.2,2.4-4,4.8-8.8,7.2c-6.4,3.2-16.4,5.6-29.6,7.6c-1.2,0.4-2.4,1.2-3.2,2.4
			c-0.8,1.2-1.2,4-2,7.6l-0.4,2c-0.4,1.6-0.8,3.6-1.2,5.2c-0.8,2-2,3.2-4.4,3.2c-1.2,0-3.2,0-6.4-0.8c-4.4-0.8-10-2-17.2-2
			c-4.4,0-8.4,0.4-12.4,1.2c-8.4,1.6-16,6.4-24,12c-10.8,7.6-22.8,16.4-41.2,16.4c-0.8,0-1.6,0-2,0c-0.4,0-0.4,0-0.8,0s-0.8,0-0.8,0
			c-18.4,0-30.4-8.8-41.6-16.4c-7.6-5.2-14.8-10.4-23.6-12c-4-0.8-8.4-1.2-12.4-1.2c-7.2,0-12.8,1.2-16.8,2h-0.4
			c-2,0.4-4.4,0.8-6.4,0.8c-2.4,0-4-0.8-4.8-3.2c-1.6-2.8-2.4-4.8-2.8-6.8l-0.4-1.2c-0.8-3.2-1.2-6-2.4-7.6c-0.4-1.2-1.6-2-2.8-2
			c-13.2-2-23.2-4.8-30-7.6c-4.4-2-7.6-4.4-8.8-6.8c-0.4-0.8-0.4-1.6-0.4-2c0-0.4,0-0.4,0-0.8c1.6-1.6,2.4-2.4,3.6-2.8
			c10.4-1.6,20-5.6,28.4-11.2c7.2-4.4,14-10.4,20-17.6c10.4-12,15.6-23.6,16.4-25.6v-0.4c2.8-5.2,3.2-10,1.6-13.6
			c-3.2-7.2-12.8-10.4-19.6-12.4L144,248c-1.2-0.4-2.4-0.8-3.2-1.2c-4.8-2-8.4-4-10.4-6c-3.2-2.8-3.6-5.6-3.2-7.2c0.4-2,2-4.4,4.8-6
			c3.6-2,8.4-2.8,11.2-1.2c9.2,4.4,16.8,5.6,22.4,2.8c1.6-0.8,2.4-2.4,2.4-4c0-2.4-0.4-4.8-0.4-7.2v-0.4
			c-1.2-20.8-2.8-46.8,3.6-61.6c4.8-10.4,10.8-19.2,18.4-26.4c6.4-6,13.6-10.8,22-14.4c14.4-6.4,27.6-7.2,34.4-7.2h6
			c6.8,0,20,0.8,34.4,7.2c8.4,3.6,15.6,8.4,22,14.4c7.6,7.2,14,16,18.4,26.4c6.8,14.8,4.8,40.8,3.6,61.2v1.2c0,1.6-0.4,2.8-0.4,4.4
			v2c0,1.6,0.8,3.2,2.4,4c1.6,0.8,3.6,1.2,5.6,1.2c0,0,0.4,0,0.8,0c4.4-0.4,9.2-1.6,14.4-4c2-0.8,4-0.8,4.8-0.8c2,0,3.6,0.4,5.6,1.2
			c4.4,1.6,7.6,4.8,7.6,8c0,1.2-0.4,3.2-2.8,5.6c-2.4,2.4-6,4.4-11.2,6.8c-0.8,0.4-2,0.8-2.8,0.8l-1.2,0.4
			c-6.4,2-16.4,5.2-19.6,12.4c-1.6,3.6-1.2,8.4,1.6,14c0,0.4,0,0.4,0.4,0.8c3.6,8,23.6,47.2,64.8,54c1.6,0.4,2.8,1.6,2.8,3.6
			C403.2,333.6,402.8,334.4,402.8,334.8z"
          />
        </g>
      </g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  )
}

export default SnapChat
