import { Box } from '@mui/material'
import { IApp } from 'interfaces/products'
import React from 'react'

import AppleIcon from '@mui/icons-material/Apple'
import YouTubeIcon from '@mui/icons-material/YouTube'
import LanguageIcon from '@mui/icons-material/Language'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import PinterestIcon from '@mui/icons-material/Pinterest'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import GoogleStore from 'components/icons/googleStore'
import Behance from 'components/icons/benhance'
import SnapChat from 'components/icons/snapChat'
import Tiktok from 'components/icons/tiktok'

type Props = {
  app: IApp
}

const SeeMore = ({ app }: Props) => {
  return (
    <Box
      className="urls"
      display="flex"
      justifyContent="center"
      sx={{
        a: {
          width: '50px',
          textAlign: 'center',
        },
      }}
    >
      {app.webUrl && (
        <>
          {app.webUrl.length > 0 && (
            <a href={app.webUrl} target="_blank" rel="noreferrer">
              <LanguageIcon
                sx={{
                  fontSize: '40px',
                  marginTop: '-1px',
                  color: 'var(--main-color)',
                }}
              />
            </a>
          )}
        </>
      )}
      {app.googlePlayUrl && (
        <>
          {app.googlePlayUrl.length > 0 && (
            <a href={app.googlePlayUrl} target="_blank" rel="noreferrer">
              <GoogleStore width={30} marginTop={-4} />
            </a>
          )}
        </>
      )}
      {app.appleStoreUrl && (
        <>
          {app.appleStoreUrl.length > 0 && (
            <a href={app.appleStoreUrl} target="_blank" rel="noreferrer">
              <AppleIcon
                sx={{
                  fontSize: '40px',
                  // width: '35px',
                  // height: '37px',
                  marginTop: '-3px',
                  color: 'var(--main-color)',
                }}
              />
            </a>
          )}
        </>
      )}
      {app.youtubeUrl && (
        <>
          {app.youtubeUrl.length > 0 && (
            <a href={app.youtubeUrl} target="_blank" rel="noreferrer">
              <YouTubeIcon
                sx={{
                  fontSize: '40px',
                  // width: '35px',
                  // height: '37px',
                  color: 'var(--main-color)',
                }}
              />
            </a>
          )}
        </>
      )}

      {app.facebookUrl && (
        <>
          {app.facebookUrl.length > 0 && (
            <a href={app.facebookUrl} target="_blank" rel="noreferrer">
              <FacebookIcon
                sx={{
                  fontSize: '40px',
                  width: '35px',
                  height: '37px',
                  color: 'var(--main-color)',
                }}
              />
            </a>
          )}
        </>
      )}
      {app.linkedInUrl && (
        <>
          {app.linkedInUrl.length > 0 && (
            <a href={app.linkedInUrl} target="_blank" rel="noreferrer">
              <LinkedInIcon
                sx={{
                  fontSize: '40px',
                  width: '35px',
                  height: '37px',
                  color: 'var(--main-color)',
                }}
              />
            </a>
          )}
        </>
      )}
      {app.pinterestUrl && (
        <>
          {app.pinterestUrl.length > 0 && (
            <a href={app.pinterestUrl} target="_blank" rel="noreferrer">
              <PinterestIcon
                sx={{
                  fontSize: '40px',
                  width: '35px',
                  height: '37px',
                  color: 'var(--main-color)',
                }}
              />
            </a>
          )}
        </>
      )}
      {app.instagramUrl && (
        <>
          {app.instagramUrl.length > 0 && (
            <a href={app.instagramUrl} target="_blank" rel="noreferrer">
              <InstagramIcon
                sx={{
                  fontSize: '40px',
                  width: '35px',
                  height: '37px',
                  color: 'var(--main-color)',
                }}
              />
            </a>
          )}
        </>
      )}
      {app.twitterUrl && (
        <>
          {app.twitterUrl.length > 0 && (
            <a href={app.twitterUrl} target="_blank" rel="noreferrer">
              <TwitterIcon
                sx={{
                  fontSize: '40px',
                  width: '35px',
                  height: '37px',
                  color: 'var(--main-color)',
                }}
              />
            </a>
          )}
        </>
      )}
      {app.tiktokUrl && (
        <>
          {app.tiktokUrl.length > 0 && (
            <a href={app.tiktokUrl} target="_blank" rel="noreferrer">
              <Tiktok width={28} marginTop={5} />
            </a>
          )}
        </>
      )}
      {app.snapchatUrl && (
        <>
          {app.snapchatUrl.length > 0 && (
            <a href={app.snapchatUrl} target="_blank" rel="noreferrer">
              <SnapChat width={28} marginTop={7} />
            </a>
          )}
        </>
      )}
      {app.behanceUrl && (
        <>
          {app.behanceUrl.length > 0 && (
            <a href={app.behanceUrl} target="_blank" rel="noreferrer">
              <Behance width={35} marginTop={3} />
            </a>
          )}
        </>
      )}
    </Box>
  )
}

export default SeeMore
