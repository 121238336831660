import { Box } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { Admin } from 'api/api-handlers/Instance'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Btn from '../loading-button/Btn'
import { useSnackbar } from 'notistack'

const MainImageBg = () => {
  const [img, setImg] = useState<any>(undefined)
  const { t, i18n } = useTranslation()
  const [loading, setLoading] = useState(false)

  const HandleChange = async (e: any) => {
    if (e.target.files) {
      document.documentElement.style.setProperty(
        '--background',
        `url(${URL.createObjectURL(e.target.files[0])})`
      )
      setImg(e.target.files[0])
    }
  }

  const createFormDate = (data: object) => {
    let formData = new FormData()

    for (const [key, value] of Object.entries(data)) {
      if (value === undefined || value === null) continue

      if (Array.isArray(value)) {
        for (const item of value) {
          formData.append(key, item)
        }
      } else formData.append(key, value)
    }
    return formData
  }

  const query = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const HandleEdit = () => {
    if (img && !loading) {
      setLoading(true)
      let formData = createFormDate({ image: img })
      Admin.patch(
        `https://hzou0g107c.execute-api.us-east-2.amazonaws.com/prod/general/image/62f58b0031802021eebd7156`,
        formData
      )
        .then((res) => {
          setLoading(false)
          query.resetQueries(['general'])
          enqueueSnackbar(t('admin.success-edit'))
        })
        .catch((err: any) => {
          if (err.response.status === 401 || err.response.status === 403) {
            localStorage.clear()
            window.location.reload()
          }
          console.log(err)
          setLoading(false)
          enqueueSnackbar(t('wrong'))
        })
    }
  }
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      style={{ direction: i18n.dir() }}
    >
      <label
        htmlFor="bg-img"
        style={{
          margin: '10px 0',
          fontSize: i18n.language !== 'ar' ? '16px' : '20px',
          fontFamily: 'var(--main-font)',
          background: '#eee',
          padding: '3px 6px',
          borderRadius: '5px',
          cursor: 'pointer',
        }}
      >
        {t('admin.edit-img')}
      </label>
      <input
        id="bg-img"
        type="file"
        accept="image/*"
        onChange={(e) => {
          HandleChange(e)
        }}
        style={{ display: 'none' }}
      />

      <Box onClick={() => HandleEdit()} textAlign="center">
        <Btn type="out-main" text={t('save')} loading={loading} />
      </Box>
    </Box>
  )
}

export default MainImageBg
