import { Box, Typography } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'
import { Admin } from 'api/api-handlers/Instance'
import API_ROUTES from 'api/routes'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Btn from '../loading-button/Btn'
import { useSnackbar } from 'notistack'

const AboutControl = ({ data }: { data: any }) => {
  const { t, i18n } = useTranslation()
  const query = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const [text, setText] = useState(data?.aboutEn.value)
  const [textAr, setTextAr] = useState(data?.aboutAr.value)

  const [loadingAdd, setLoadingAdd] = useState(false)
  const [loadingAddAr, setLoadingAddAr] = useState(false)

  const HandleChange = (e: any) => {
    if (e.target.name === 'aboutEn') setText(e.target.value)
    else if (e.target.name === 'aboutAr') setTextAr(e.target.value)
  }

  useEffect(() => {
    setText(data?.aboutEn.value)
    setTextAr(data?.aboutAr.value)
  }, [data])

  const HandleEdit = async (key: string, id: string) => {
    if (key === 'en') setLoadingAdd(true)
    else if (key === 'ar') setLoadingAddAr(true)

    Admin.patch(`${API_ROUTES.GENERAL_ROUTES.CREATE_POST_DELETE}/${id}`, {
      value: key === 'en' ? text : textAr,
    })
      .then(async (res) => {
        await query.refetchQueries(['general'])
        if (key === 'en') setLoadingAdd(false)
        else setLoadingAddAr(false)
        enqueueSnackbar(t('admin.success-edit'))
      })
      .catch((err: any) => {
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear()
          window.location.reload()
        }
        if (key === 'en') setLoadingAdd(false)
        else setLoadingAddAr(false)
        enqueueSnackbar(t('wrong'))
      })
  }

  const BOX_STYLES = {
    marginBottom: '30px',

    textarea: {
      '&:placeholder': {
        textAlign: i18n.language !== 'ar' ? 'left' : 'right',
      },
    },
  }
  return (
    <Box>
      <Box sx={BOX_STYLES}>
        <Typography
          sx={{
            fontFamily: 'var(--main-font)',
            fontSize: i18n.language !== 'ar' ? '18px' : '20px',
            direction: i18n.dir(),
          }}
        >
          {t('admin.about-en')}
        </Typography>
        <textarea
          name="aboutEn"
          rows={6}
          onChange={HandleChange}
          value={text}
          // value={isLoading ? 'isLoading..' : text}
          style={{
            width: '100%',
            padding: '10px 10px',
            lineHeight: '1.8',
            fontSize: '16px',
            borderRadius: '15px',
            direction: 'ltr',
            margin: '10px 0',
          }}
        />
        <Box
          onClick={() => HandleEdit('en', data.aboutEn._id)}
          textAlign="center"
        >
          <Btn
            type="out-main"
            text={t('admin.save-edit')}
            loading={loadingAdd}
          />
        </Box>
      </Box>
      <Box sx={BOX_STYLES}>
        <Typography
          sx={{
            fontFamily: 'var(--main-font)',
            fontSize: i18n.language !== 'ar' ? '18px' : '20px',
            direction: i18n.dir(),
          }}
        >
          {t('admin.about-ar')}
        </Typography>

        <textarea
          name="aboutAr"
          rows={6}
          onChange={HandleChange}
          value={textAr}
          // value={isLoading ? 'isLoading..' : textAr}
          style={{
            width: '100%',
            padding: '10px 10px',
            lineHeight: '1.8',
            fontSize: '18px',
            borderRadius: '15px',
            direction: 'rtl',
            fontFamily: 'var(--Ar-font)',
            margin: '10px 0',
          }}
        />

        <Box
          onClick={() => HandleEdit('ar', data.aboutAr._id)}
          textAlign="center"
        >
          <Btn
            type="out-main"
            text={t('admin.save-edit')}
            loading={loadingAddAr}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default AboutControl
