import { Box, IconButton } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye'
import LensIcon from '@mui/icons-material/Lens'
type Props = {
  setStatus: any
  status: string
}

const RadioInputs = ({ setStatus, status }: Props) => {
  const { i18n, t } = useTranslation()
  return (
    <Box
      sx={{
        textAlign: { lg: 'center' },
        display: { lg: 'flex' },
        justifyContent: 'center',
        '@media screen and (max-width:900px)': {
          display: 'flex',
        },
        '@media screen and (max-width:650px)': {
          justifyContent: 'flex-start',
          margin: '20px 0',
        },
        '@media screen and (max-width:450px)': {
          display: 'block',
          margin: '20px 0',
        },
      }}
    >
      <Box
        onClick={() => setStatus('soon')}
        sx={{
          fontSize: i18n.language !== 'ar' ? '16px' : '18px',
          fontFamily: 'var(--main-font)',
          fontWeight: status === 'soon' ? 600 : 500,
          cursor: 'pointer',
        }}
      >
        <IconButton sx={{ svg: { fontSize: '20px' } }}>
          {status === 'soon' ? <LensIcon /> : <PanoramaFishEyeIcon />}
        </IconButton>

        {t('soon')}
      </Box>
      <Box
        onClick={() => setStatus('new')}
        sx={{
          fontSize: i18n.language !== 'ar' ? '16px' : '18px',
          fontFamily: 'var(--main-font)',
          fontWeight: status === 'new' ? 600 : 500,
          cursor: 'pointer',
          margin: { lg: '0px 7px' },
        }}
      >
        <IconButton sx={{ svg: { fontSize: '20px' } }}>
          {status === 'new' ? <LensIcon /> : <PanoramaFishEyeIcon />}
        </IconButton>
        {t('new')}
      </Box>
      <Box
        onClick={() => setStatus('no_mark')}
        sx={{
          fontSize: i18n.language !== 'ar' ? '16px' : '18px',
          fontFamily: 'var(--main-font)',
          fontWeight: status === 'no_mark' ? 600 : 500,
          cursor: 'pointer',
        }}
      >
        <IconButton sx={{ svg: { fontSize: '20px' } }}>
          {status === 'no_mark' ? <LensIcon /> : <PanoramaFishEyeIcon />}
        </IconButton>
        {t('no_mark')}
      </Box>
    </Box>
  )
}

export default RadioInputs
