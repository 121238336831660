import { Box } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-scroll'

const Navbar = ({
  setContent,
  content,
}: {
  setContent: any
  content: number
}) => {
  const { t, i18n } = useTranslation()
  const NAV_STYLES = {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '25px 0',
    direction: i18n.dir(),
    div: {
      userSelect: 'none',
      color: '#fff',
      textTransform: 'capitalize',
      fontFamily: 'var(--main-font)',
      fontWeight: i18n.language !== 'ar' ? 500 : 300,
      fontSize: '20px',
      cursor: 'pointer',
      transition: '0.3s',
      textDecoration: 'none',
      opacity: 0.7,
      '&:active': {
        transform: 'scale(0.95)',
      },
      '&.active': {
        opacity: 1,
      },
    },
  }
  return (
    <Box sx={NAV_STYLES}>
      <div
        className={content === 0 ? 'active' : ''}
        onClick={() => setContent(0)}
      >
        {t('home')}
      </div>
      <div
        className={content === 1 ? 'active' : ''}
        onClick={() => setContent(1)}
      >
        {t('contacts')}
      </div>
      <Link to="about" spy={true} smooth={true}>
        <div>{t('about')}</div>
      </Link>
    </Box>
  )
}

export default Navbar
