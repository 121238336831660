import { Box, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import { Admin } from 'api/api-handlers/Instance'
import API_ROUTES from 'api/routes'
import { useTranslation } from 'react-i18next'
import Btn from '../loading-button/Btn'
import { useSnackbar } from 'notistack'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import PinterestIcon from '@mui/icons-material/Pinterest'

type Props = {
  data: any
}

const AboutMe = ({ data }: Props) => {
  const { t, i18n } = useTranslation()
  const query = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()

  const [en, setEn] = useState(data?.aboutMeEn.value)
  const [ar, setAr] = useState(data?.aboutMeAr.value)
  const [face, setFace] = useState(data?.facebook.value)
  const [instagram, setInstagram] = useState(data?.instagram.value)
  const [pin, setPin] = useState(data?.pinterest.value)

  const [loadingAdd, setLoadingAdd] = useState(false)
  const [loadingAddAr, setLoadingAddAr] = useState(false)

  useEffect(() => {
    setEn(data?.aboutMeEn.value)
    setAr(data?.aboutMeAr.value)
    setPin(data?.pinterest.value)
    setFace(data?.facebook.value)
    setInstagram(data?.instagram.value)
  }, [data])
  const HandleChange = (e: any) => {
    if (e.target.name === 'en' && e.target.value.length < 251) {
      setEn(e.target.value)
    } else if (e.target.name === 'ar' && e.target.value.length < 251) {
      setAr(e.target.value)
    } else if (e.target.name === 'face') {
      setFace(e.target.value)
    } else if (e.target.name === 'instagram') {
      setInstagram(e.target.value)
    } else if (e.target.name === 'pin') {
      setPin(e.target.value)
    }
  }
  const HandleEdit = (key: string, id: string) => {
    if (key === 'en') setLoadingAdd(true)
    else if (key === 'ar') setLoadingAddAr(true)

    Admin.patch(`${API_ROUTES.GENERAL_ROUTES.CREATE_POST_DELETE}/${id}`, {
      value: key === 'en' ? en : ar,
    })
      .then(async (res) => {
        await query.refetchQueries(['general'])
        if (key === 'en') setLoadingAdd(false)
        else setLoadingAddAr(false)
        enqueueSnackbar(t('admin.success-edit'))
      })
      .catch((err: any) => {
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear()
          window.location.reload()
        }
        if (key === 'en') setLoadingAdd(false)
        else setLoadingAddAr(false)
        enqueueSnackbar(t('wrong'))
      })
  }

  const [loadFace, setLoadFace] = useState(false)
  const [loadInstagram, setLoadInstagram] = useState(false)
  const [loadPin, setLoadPin] = useState(false)

  const HandleSocialMedia = (key: string, id: number, val: any) => {
    if (key === 'face') setLoadFace(true)
    if (key === 'instagram') setLoadInstagram(true)
    if (key === 'pin') setLoadPin(true)
    // console.log(val)

    Admin.patch(`${API_ROUTES.GENERAL_ROUTES.CREATE_POST_DELETE}/${id}`, {
      value: val,
    })
      .then(async (res) => {
        await query.refetchQueries(['general'])
        if (key === 'face') setLoadFace(false)
        if (key === 'instagram') setLoadInstagram(false)
        if (key === 'pin') setLoadPin(false)
        enqueueSnackbar(t('admin.success-edit'))
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          localStorage.clear()
          window.location.reload()
        }
        if (key === 'face') setLoadFace(false)
        if (key === 'instagram') setLoadInstagram(false)
        if (key === 'pin') setLoadPin(false)
        enqueueSnackbar(t('wrong'))
        console.log(err)
      })
  }
  return (
    <div>
      <Grid container margin="0px 0px 30px" dir={i18n.dir()}>
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: 'flex',
            marginBottom: '15px',
            '@media screen and (max-width:390px)': {
              display: 'block',
              marginBottom: '0',
              input: { width: '100%' },
            },
          }}
        >
          <Box display="flex" marginBottom="10px">
            <Box
              sx={{
                width: '30px',
                position: 'relative',
                top: '3px',
                svg: { fontSize: '30px' },
              }}
            >
              <FacebookIcon />
            </Box>
            <input
              value={face}
              name="face"
              onChange={HandleChange}
              style={{
                borderRadius: '27px',
                border: '1px solid #999',
                padding: '3px 5px',
                fontSize: '16px',
                fontFamily: 'var(--En-font)',
                color: '#555',
                margin: '0px 5px',
                direction: 'ltr',
              }}
            />
          </Box>

          <Box
            onClick={() => HandleSocialMedia('face', data.facebook._id, face)}
            sx={{
              marginTop: '-2px',
              transform: 'scale(0.92)',
              '@media screen and (max-width:390px)': {
                marginTop: '0',
                textAlign: 'center',
                marginBottom: '15px',
              },
            }}
          >
            <Btn text={t('save')} loading={loadFace} type="out-main" />
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: 'flex',
            marginBottom: '15px',
            '@media screen and (max-width:390px)': {
              display: 'block',
              marginBottom: '0',
              input: { width: '100%' },
            },
          }}
        >
          <Box display="flex" marginBottom="10px">
            <Box
              sx={{
                width: '30px',
                position: 'relative',
                top: '3px',
                svg: { fontSize: '30px' },
              }}
            >
              <InstagramIcon />
            </Box>
            <input
              value={instagram}
              name="instagram"
              onChange={HandleChange}
              style={{
                borderRadius: '27px',
                border: '1px solid #999',
                padding: '3px 5px',
                fontSize: '16px',
                fontFamily: 'var(--En-font)',
                color: '#555',
                margin: '0px 5px',
                direction: 'ltr',
              }}
            />
          </Box>
          <Box
            onClick={() =>
              HandleSocialMedia('instagram', data.instagram._id, instagram)
            }
            sx={{
              marginTop: '-2px',
              transform: 'scale(0.92)',
              '@media screen and (max-width:390px)': {
                marginTop: '0',
                textAlign: 'center',
                marginBottom: '15px',
              },
            }}
          >
            <Btn text={t('save')} loading={loadInstagram} type="out-main" />
          </Box>
        </Grid>
        <Grid
          item
          md={6}
          xs={12}
          sx={{
            display: 'flex',
            marginBottom: '15px',
            '@media screen and (max-width:390px)': {
              display: 'block',
              marginBottom: '0',
              input: { width: '100%' },
            },
          }}
        >
          <Box display="flex" marginBottom="10px">
            <Box
              sx={{
                width: '30px',
                position: 'relative',
                top: '3px',
                svg: { fontSize: '30px' },
              }}
            >
              <PinterestIcon />
            </Box>
            <input
              value={pin}
              name="pin"
              onChange={HandleChange}
              style={{
                borderRadius: '27px',
                border: '1px solid #999',
                padding: '3px 5px',
                fontSize: '16px',
                fontFamily: 'var(--En-font)',
                color: '#555',
                margin: '0px 5px',
                direction: 'ltr',
              }}
            />
          </Box>
          <Box
            onClick={() => HandleSocialMedia('pin', data.pinterest._id, pin)}
            sx={{
              marginTop: '-2px',
              transform: 'scale(0.92)',
              '@media screen and (max-width:390px)': {
                marginTop: '0',
                textAlign: 'center',
                marginBottom: '15px',
              },
            }}
          >
            <Btn text={t('save')} loading={loadPin} type="out-main" />
          </Box>
        </Grid>
      </Grid>
      <Box margin="30px 0">
        <Typography
          sx={{
            fontFamily: 'var(--main-font)',
            fontSize: i18n.language !== 'ar' ? '18px' : '20px',
            direction: i18n.dir(),
          }}
        >
          {t('admin.about-me-en')}
        </Typography>
        <textarea
          name="en"
          rows={4}
          value={en}
          style={{
            width: '100%',
            padding: '10px 10px',
            lineHeight: '1.8',
            fontSize: '16px',
            borderRadius: '15px',
            direction: 'ltr',
            margin: '10px 0',
          }}
          onChange={HandleChange}
        />
        <Typography fontSize="14px">{en?.length}/250</Typography>
        <Box
          onClick={() => HandleEdit('en', data.aboutMeEn._id)}
          textAlign="center"
        >
          <Btn
            type="out-main"
            text={t('admin.save-edit')}
            loading={loadingAdd}
          />
        </Box>
      </Box>
      <Box margin="30px 0">
        <Typography
          sx={{
            fontFamily: 'var(--main-font)',
            fontSize: i18n.language !== 'ar' ? '18px' : '20px',
            direction: i18n.dir(),
          }}
        >
          {t('admin.about-me-ar')}
        </Typography>
        <textarea
          name="ar"
          value={ar}
          rows={4}
          style={{
            width: '100%',
            padding: '10px 10px',
            lineHeight: '1.8',
            fontSize: '18px',
            borderRadius: '15px',
            direction: 'rtl',
            fontFamily: 'var(--Ar-font)',
            margin: '10px 0',
          }}
          onChange={HandleChange}
        />
        <Typography fontSize="14px">{ar?.length}/250</Typography>

        <Box
          onClick={() => HandleEdit('ar', data.aboutMeAr._id)}
          textAlign="center"
        >
          <Btn
            type="out-main"
            text={t('admin.save-edit')}
            loading={loadingAddAr}
          />
        </Box>
      </Box>
    </div>
  )
}

export default AboutMe
