import * as React from 'react'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { Button, Menu, Typography } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useTranslation } from 'react-i18next'
import List from './List'
import contactQueries from 'api/react-query/useContactQueries'
import { IReason } from 'interfaces/contact'

export default function Dropdown({
  reason,
  setReason,
  setNReason,
  nReason,
}: {
  reason: IReason | undefined
  setReason: any
  setNReason: any
  nReason: boolean
}) {
  const { t, i18n } = useTranslation()
  const BTN_STYLES = {
    direction: i18n.dir(),
    width: '100%',
    justifyContent: 'space-between',
    textTransform: 'none',
    color: '#fff',
    border: nReason ? '1px solid #d80a0a' : '1px solid #707070',
    fontFamily: 'var(--main-font) !important',

    svg: {
      color: '#fff',
    },
    marginBottom: '15px',
  }

  const { data } = contactQueries.useReasonsQuery()
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button {...bindTrigger(popupState)} sx={BTN_STYLES}>
            <Typography fontFamily="var(--main-font)" fontSize="20px">
              {reason
                ? i18n.language !== 'ar'
                  ? reason.nameEn
                  : reason.nameAr
                : t('reason')}
            </Typography>
            <ArrowDropDownIcon />
          </Button>

          <Menu {...bindMenu(popupState)} style={{ width: '100%' }}>
            <List
              data={data}
              reason={reason}
              popupState={popupState}
              setReason={setReason}
              setNReason={setNReason}
            />
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  )
}
