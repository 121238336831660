import React, { useEffect } from 'react'
import AdminPage from './admin'
import HomePage from './main'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import API_ROUTES from 'api/routes'
import { Api } from 'api/api-handlers/Instance'
import { useQuery } from '@tanstack/react-query'

const Pages = () => {
  const { data: color } = useQuery(['general'], async () => {
    let data = await Api.get(API_ROUTES.GENERAL_ROUTES.GET)
    return data.data.data
  })

  useEffect(() => {
    if (color) {
      document.documentElement.style.setProperty(
        '--main-color',
        color?.mainColor.value
      )
      document.documentElement.style.setProperty(
        '--background',
        `url(https://mfas-assets-public.s3.us-east-2.amazonaws.com/${color?.backgroundImage.value})`
      )

      document.documentElement.style.setProperty(
        '--first-header-color',
        `rgba(${JSON.parse(color?.headerColor.value)[0][0]},
        ${JSON.parse(color?.headerColor.value)[0][1]},
        ${JSON.parse(color?.headerColor.value)[0][2]},
        ${JSON.parse(color?.headerColor.value)[0][3]})`
      )

      document.documentElement.style.setProperty(
        '--second-header-color',
        `rgba(${JSON.parse(color?.headerColor.value)[1][0]},
        ${JSON.parse(color?.headerColor.value)[1][1]},
        ${JSON.parse(color?.headerColor.value)[1][2]},
        ${JSON.parse(color?.headerColor.value)[1][3]})`
      )

      document.documentElement.style.setProperty(
        '--first-footer-color',
        `rgb(${JSON.parse(color?.footerColor.value)[0][0]},
        ${JSON.parse(color?.footerColor.value)[0][1]},
        ${JSON.parse(color?.footerColor.value)[0][2]})`
      )

      document.documentElement.style.setProperty(
        '--second-footer-color',
        `rgb(${JSON.parse(color?.footerColor.value)[1][0]},
        ${JSON.parse(color?.footerColor.value)[1][1]},
        ${JSON.parse(color?.footerColor.value)[1][2]})`
      )
    }
  }, [color])

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/admin" element={<AdminPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  )
}

export default Pages
