/* eslint-disable no-restricted-globals */
import React, { useState } from 'react'
import LanguageIcon from '@mui/icons-material/Language'
import { useTranslation } from 'react-i18next'
import { Box, IconButton } from '@mui/material'

const ICON = {
  svg: {
    fontSize: '35px',
    color: '#fefefe',
  },
}

const BTNS = {
  height: '0px',
  transition: '0.3s',
  overflow: 'hidden',
  '&.active': {
    height: '65px',
  },
  div: {
    color: '#99FFFF',
    padding: '3px 0',
    borderRadius: '0',
    margin: 'auto',
    marginBottom: '10px',
    minWidth: '40px',
    width: 'fit-content',
    display: 'block',
    fontFamily: 'var(--En-font)',
    fontWeight: 900,
    cursor: 'pointer',
    transition: '0.3s',
    '&:active': {
      transform: 'scale(0.95)',
    },
  },
  'div.active': {
    borderBottom: '1px solid #99FFFF',
  },
}

type Props = {
  topColor?: string | undefined
  bottomColor?: string | undefined
}
const ChangeLang = ({ topColor, bottomColor }: Props) => {
  const { i18n } = useTranslation()
  const [open, setOpen] = useState(false)

  const BOX_STYLES = {
    background: `linear-gradient(-120deg,${
      topColor ? topColor : ' var(--first-header-color)'
    } , ${bottomColor ? bottomColor : 'var(--second-header-color)'})`,
    color: '#fff',
    padding: '10px 3px 15px',

    '@media screen and (max-width: 670px) ': {
      background: `linear-gradient(0deg,${
        topColor ? topColor : ' var(--first-header-color)'
      } , ${bottomColor ? bottomColor : 'var(--second-header-color)'})`,
    },
  }

  return (
    <Box sx={BOX_STYLES}>
      <IconButton sx={ICON} onClick={() => setOpen(!open)}>
        <LanguageIcon />
      </IconButton>
      <Box sx={BTNS} className={open ? 'active' : ''}>
        <div
          onClick={() => {
            i18n.changeLanguage('en')
            document.documentElement.style.setProperty(
              '--main-font',
              'Montserrat'
            )
          }}
          className={i18n.language !== 'ar' ? 'active' : ''}
        >
          ENG
        </div>
        <div
          onClick={() => {
            i18n.changeLanguage('ar')
            document.documentElement.style.setProperty(
              '--main-font',
              'Aljazeera'
            )
          }}
          className={i18n.language === 'ar' ? 'active' : ''}
        >
          ARA
        </div>
      </Box>
    </Box>
  )
}

export default ChangeLang
