/* eslint-disable @typescript-eslint/no-redeclare */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { FC } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import MessageList from './MessagesList'
import { InfiniteData } from '@tanstack/react-query'
import { IMessage } from 'interfaces/contact'

interface InfiniteMessagesList {
  data:
    | InfiniteData<{
        data: IMessage[]
        pageParam: any
      }>
    | undefined
  limit: number
  fetchNextPage: any
  hasNextPage: boolean | undefined
}

const InfiniteMessagesList: FC<InfiniteMessagesList> = ({
  data,
  limit,
  fetchNextPage,
  hasNextPage,
}) => {
  return (
    <InfiniteScroll
      style={{ overflow: 'hidden', padding: 2 }}
      dataLength={data?.pages.length || limit}
      next={fetchNextPage}
      hasMore={!!hasNextPage}
      loader={<div>Loading ....</div>}
      // {<LoadingPlaceholder height="100px" />}
    >
      <>
        {data?.pages.map((page) => (
          <MessageList messages={page.data} key={page.pageParam} />
        ))}
      </>
    </InfiniteScroll>
  )
}

export default InfiniteMessagesList
