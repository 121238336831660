/* eslint-disable no-restricted-globals */
import API_ROUTES from 'api/routes'
import { Api } from './Instance'

const sendMessage = async (payload: unknown) => {
  const { data } = await Api.post(API_ROUTES.AUTH_ROUTES.LOGIN, payload)
  await localStorage.setItem('token', data.token)
  location.reload()
}
const authApi = {
  sendMessage,
}

export default authApi
