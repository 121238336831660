import React, { useState } from 'react'
import AboutTabs from './control-about/tabs-about'
import ContactControl from './control-contact/contact-control'
import ProductControl from './control-product/tabs-content'
import MainControl from './control-website/main-control'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { IconButton } from '@mui/material'
import GeneralControl from './general-control/general-control'

const Modal = () => {
  const [control, setControl] = useState([-1, 1])
  return (
    <div>
      {control[0] !== -1 && (
        <IconButton
          sx={{ position: 'absolute' }}
          onClick={() => setControl([-1, 1])}
        >
          <ArrowBackIcon />
        </IconButton>
      )}
      {control[0] === -1 && <MainControl setControl={setControl} />}
      {control[0] === 0 && (
        <ProductControl control={control[1]} setControl={setControl} />
      )}
      {control[0] === 1 && <AboutTabs />}
      {control[0] === 2 && <ContactControl />}
      {control[0] === 3 && <GeneralControl setControl={setControl} />}
    </div>
  )
}

export default Modal
