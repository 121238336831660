const AUTH_ROUTES = {
  LOGIN: '/auth/public/login',
}

const PRODUCT_ROUTES = {
  GET: '/general/public/app',
  CREATE_POST_DELETE: '/general/app',
}

const GENERAL_ROUTES = {
  GET: '/general/public/',
  CREATE_POST_DELETE: '/general',
  UPDATE_BG_IMG: '/general/image/',
}

const CONTACT_ROUTES = {
  GET_REASONS: '/general/public/contact',
  CREATE_POST_DELETE: '/general/contact',
}

const MESSAGES_ROUTES = {
  GET: '/general/message',
  CONTACT: '/general/public/contact',
  DELETE: '/general/message',
}

const API_ROUTES = {
  AUTH_ROUTES,
  PRODUCT_ROUTES,
  GENERAL_ROUTES,
  CONTACT_ROUTES,
  MESSAGES_ROUTES,
}

export default API_ROUTES
