import { IApp } from 'interfaces/products'
import * as React from 'react'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'
import { Button, Menu, Typography } from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { useTranslation } from 'react-i18next'
import List from './List'
import productQueries from 'api/react-query/useAppQueries'

type Props = {
  item: IApp | undefined
  setItem: any
}

const GetApps = ({ item, setItem }: Props) => {
  const { i18n } = useTranslation()
  const BTN_STYLES = {
    direction: i18n.dir(),
    width: '400px',
    justifyContent: 'space-between',
    textTransform: 'none',
    color: '#333',
    border: '1px solid #707070',
    fontFamily: 'var(--main-font) !important',

    svg: {
      color: '#333',
    },
    marginBottom: '15px',
    '@media screen and (max-width:550px)': { width: '300px' },
    '@media screen and (max-width:350px)': { width: '270px' },
  }

  const [offset] = React.useState(0)

  const [limit] = React.useState(100000)

  const { data, hasNextPage, fetchNextPage } = productQueries.useAppQuery({
    offset,
    limit,
  })
  return (
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <React.Fragment>
          <Button {...bindTrigger(popupState)} sx={BTN_STYLES}>
            <Typography
              fontFamily="var(--main-font)"
              fontSize={i18n.language === 'en' ? '16px' : '18px'}
            >
              {i18n.language !== 'ar' ? item?.nameEn : item?.nameAr}
            </Typography>
            <ArrowDropDownIcon />
          </Button>

          <Menu
            {...bindMenu(popupState)}
            sx={
              {
                // width: '400px',
                // '@media screen and (max-width:550px)': { width: '300px' },
              }
            }
          >
            <List
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              limit={limit}
              data={data}
              item={item}
              popupState={popupState}
              setItem={setItem}
            />
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  )
}

export default GetApps
