import React, { useState } from 'react'

import { Box, Button, Grid, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import AppleIcon from '@mui/icons-material/Apple'
import YouTubeIcon from '@mui/icons-material/YouTube'
import LanguageIcon from '@mui/icons-material/Language'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import PinterestIcon from '@mui/icons-material/Pinterest'
import TwitterIcon from '@mui/icons-material/Twitter'
import InstagramIcon from '@mui/icons-material/Instagram'
import GoogleStore from 'components/icons/googleStore'
import Tiktok from 'components/icons/tiktok'
import SnapChat from 'components/icons/snapChat'
import Behance from 'components/icons/benhance'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

type Props = {
  app: any
  HandleChange: (e: any) => void
}

const UrlsInputs = ({ app, HandleChange }: Props) => {
  const { t, i18n } = useTranslation()
  const [open, setOpen] = useState(false)
  return (
    <Box>
      <Typography
        sx={{
          fontSize: i18n.language === 'ar' ? '26px' : '22px',
          fontFamily: 'var(--main-font)',
          textAlign: 'center',
          margin: '10px 10px 20px',
        }}
      >
        {t(`admin.add-urls`)}
      </Typography>
      <Box
        sx={{
          maxHeight: open ? '1000px' : '50px',
          height: '0',
          // transform: open ? 'translateY(0px)' : 'translateY(-100px)',
          transition: '1s',
          overflowY: 'hidden',
        }}
      >
        <Grid container spacing={2} dir={i18n.dir()}>
          <Grid item xl={3} lg={4} md={6} xs={12} display="flex">
            <LanguageIcon
              sx={{
                fontSize: '40px',
                width: '35px',
                height: '37px',
                color: 'var(--main-color)',
              }}
            />
            <input
              name="webUrl"
              type="text"
              style={{ height: '35px', margin: '0px 5px' }}
              value={app.webUrl}
              onChange={(e) => HandleChange(e)}
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} xs={12} display="flex">
            <AppleIcon
              sx={{
                fontSize: '40px',
                width: '35px',
                height: '37px',
                color: 'var(--main-color)',
              }}
            />
            <input
              name="appleStoreUrl"
              type="text"
              style={{ height: '35px', margin: '0px 5px' }}
              onChange={(e) => HandleChange(e)}
              value={app.appleStoreUrl}
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} xs={12} display="flex">
            <Box sx={{ width: '35px', height: '37px', textAlign: 'center' }}>
              <GoogleStore width={28} marginTop={-7} />
            </Box>

            <input
              name="googlePlayUrl"
              type="text"
              style={{ height: '35px', margin: '0px 5px' }}
              value={app.googlePlayUrl}
              onChange={(e) => HandleChange(e)}
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} xs={12} display="flex">
            <YouTubeIcon
              sx={{
                fontSize: '40px',
                width: '35px',
                height: '37px',
                color: 'var(--main-color)',
              }}
            />
            <input
              name="youtubeUrl"
              type="text"
              style={{ height: '35px', margin: '0px 5px' }}
              value={app.youtubeUrl}
              onChange={(e) => HandleChange(e)}
            />
          </Grid>

          <Grid item xl={3} lg={4} md={6} xs={12} display="flex">
            <FacebookIcon
              sx={{
                fontSize: '40px',
                width: '35px',
                height: '37px',
                color: 'var(--main-color)',
              }}
            />
            <input
              name="facebookUrl"
              type="text"
              style={{ height: '35px', margin: '0px 5px' }}
              value={app.facebookUrl}
              onChange={(e) => HandleChange(e)}
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} xs={12} display="flex">
            <InstagramIcon
              sx={{
                fontSize: '40px',
                width: '35px',
                height: '37px',
                color: 'var(--main-color)',
              }}
            />
            <input
              name="instagramUrl"
              type="text"
              style={{ height: '35px', margin: '0px 5px' }}
              value={app.instagramUrl}
              onChange={(e) => HandleChange(e)}
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} xs={12} display="flex">
            <TwitterIcon
              sx={{
                fontSize: '40px',
                width: '35px',
                height: '37px',
                color: 'var(--main-color)',
              }}
            />
            <input
              name="twitterUrl"
              type="text"
              style={{ height: '35px', margin: '0px 5px' }}
              value={app.twitterUrl}
              onChange={(e) => HandleChange(e)}
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} xs={12} display="flex">
            <LinkedInIcon
              sx={{
                fontSize: '40px',
                width: '35px',
                height: '37px',
                color: 'var(--main-color)',
              }}
            />
            <input
              name="linkedInUrl"
              type="text"
              style={{ height: '35px', margin: '0px 5px' }}
              value={app.linkedInUrl}
              onChange={(e) => HandleChange(e)}
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} xs={12} display="flex">
            <PinterestIcon
              sx={{
                fontSize: '40px',
                width: '35px',
                height: '37px',
                color: 'var(--main-color)',
              }}
            />
            <input
              name="pinterestUrl"
              type="text"
              style={{ height: '35px', margin: '0px 5px' }}
              value={app.pinterestUrl}
              onChange={(e) => HandleChange(e)}
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} xs={12} display="flex">
            <Box sx={{ width: '35px', height: '37px', textAlign: 'center' }}>
              <Tiktok width={28} marginTop={5} />
            </Box>

            <input
              name="tiktokUrl"
              type="text"
              style={{ height: '35px', margin: '0px 5px' }}
              value={app.tiktokUrl}
              onChange={(e) => HandleChange(e)}
            />
          </Grid>
          <Grid item xl={3} lg={4} md={6} xs={12} display="flex">
            <Box sx={{ width: '35px', height: '37px', textAlign: 'center' }}>
              <SnapChat width={28} marginTop={3} />
            </Box>

            <input
              name="snapchatUrl"
              type="text"
              style={{ height: '35px', margin: '0px 5px' }}
              value={app.snapchatUrl}
              onChange={(e) => HandleChange(e)}
            />
          </Grid>

          <Grid item xl={3} lg={4} md={6} xs={12} display="flex">
            <Box sx={{ width: '35px', height: '37px', textAlign: 'center' }}>
              <Behance width={30} marginTop={3} />
            </Box>

            <input
              name="behanceUrl"
              type="text"
              style={{ height: '35px', margin: '0px 5px' }}
              value={app.behanceUrl}
              onChange={(e) => HandleChange(e)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box textAlign="center" onClick={() => setOpen(!open)}>
        <Button
          sx={{
            transition: '0.5s',
            transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
            flexDirection: 'column',
            borderRadius: '50%',
            textTransform: 'capitalize',
          }}
        >
          <Typography
            sx={{
              fontSize: i18n.language !== 'ar' ? '12px' : '14px',
              transition: '1s',
              transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
              fontFamily: 'var(--main-font)',
              color: 'var(--main-color)',
            }}
          >
            {!open ? t('more') : t('less')}
          </Typography>
          <KeyboardArrowDownIcon
            sx={{
              transition: '0.5s',
              fontSize: '30px',
            }}
          />
        </Button>
      </Box>
    </Box>
  )
}

export default UrlsInputs
